import api from '@shared/api';
import {
    AuthorizeAuth0ReqT,
    AuthorizeAuth0RespT,
    ChangeLanguageReqT,
    ChangeLanguageRespT,
    GetAuthUserReqT,
    GetAuthUserRespT,
    GetPermissionRespT,
    LoginReqT,
    LoginRespT,
    LogoutReqT,
    LogoutRespT,
    RegisterReqT,
    RegisterRespT,
    ResetPasswordReqT,
    ResetPasswordRespT,
    SendResetPasswordLinkReqT,
    SendResetPasswordLinkRespT,
    ValidateCookieReqT,
    ValidateCookieRespT,
    VerifyEmailReqT,
    VerifyEmailRespT,
} from './authApi.types';

export default api.injectEndpoints({
    endpoints: (builder) => ({
        changeLanguage: builder.mutation<ChangeLanguageRespT, ChangeLanguageReqT>({
            query: (data) => ({
                url: `/v3/auth/changelanguage`,
                method: 'PUT',
                body: data,
            }),
        }),

        authorizeAuth0: builder.mutation<AuthorizeAuth0RespT, AuthorizeAuth0ReqT>({
            query: (data) => ({
                url: `/v3/auth/authorize/auth0`,
                method: 'POST',
                body: data,
            }),
        }),

        getAuthUser: builder.query<GetAuthUserRespT, GetAuthUserReqT>({
            query: () => ({
                url: '/v3/auth/user',
                method: 'GET',
            }),
        }),

        login: builder.mutation<LoginRespT, LoginReqT>({
            query: (data) => ({
                url: `/v3/auth/login`,
                method: 'POST',
                body: data,
            }),
        }),

        logout: builder.mutation<LogoutRespT, LogoutReqT>({
            query: () => ({
                url: '/v3/auth/logout',
                method: 'POST',
            }),
        }),

        register: builder.mutation<RegisterRespT, RegisterReqT>({
            query: (data) => ({
                url: '/v3/auth/register',
                method: 'POST',
                body: { ...data, password_confirmation: data.password },
            }),
        }),

        resendVerifyEmail: builder.query<void, void>({
            query: () => ({
                url: `/v1/auth/email/resend`,
                method: 'GET',
            }),
        }),

        resetPassword: builder.mutation<ResetPasswordRespT, ResetPasswordReqT>({
            query: (data) => ({
                url: `/v3/auth/resetpassword`,
                method: 'POST',
                body: data,
            }),
        }),

        sendResetPasswordLink: builder.mutation<SendResetPasswordLinkRespT, SendResetPasswordLinkReqT>({
            query: (data) => ({
                url: `/v3/auth/forgotpassword`,
                method: 'POST',
                body: data,
            }),
        }),

        verifyEmail: builder.query<VerifyEmailRespT, VerifyEmailReqT>({
            query: (data) => ({
                url: `/v1/auth/email/verify/${data.hashedId}?expires=${data.expires}&signature=${data.signature}`,
                method: 'GET',
            }),
        }),

        validateCookie: builder.query<ValidateCookieRespT, ValidateCookieReqT>({
            query: () => ({
                url: `/v3/auth/validateCookie`,
                method: 'GET',
            }),
        }),

        getPermissions: builder.query<GetPermissionRespT, void>({
            query: () => ({
                url: `/v3/auth/permissions`,
                method: 'GET',
            }),
        }),
    }),
});
