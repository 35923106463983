import { Shadows } from '@mui/material/styles/shadows';

const shadows: Shadows = [
    'none',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(176, 125, 74, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
    '0px 6px 6px rgba(5, 40, 72, 0.15)',
];

export default shadows;
