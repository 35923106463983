import { PhT } from '../../reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const PH_LIST_GET_LOADING = 'PH_LIST_GET_LOADING';
export const PH_LIST_GET_SUCCESS = 'PH_LIST_GET_SUCCESS';
export const PH_LIST_GET_FAILED = 'PH_LIST_GET_FAILED';

export type PhListGetSuccessDataT = PhT[];

export type PhListGetLoadingT = {
    type: typeof PH_LIST_GET_LOADING;
};

export type PhListGetSuccessT = {
    type: typeof PH_LIST_GET_SUCCESS;
    payload: PhListGetSuccessDataT;
};

export type PhListGetFailedT = {
    type: typeof PH_LIST_GET_FAILED;
    error: string;
};

export type PhListGetDispatchType = PhListGetLoadingT | PhListGetSuccessT | PhListGetFailedT;

export type PhListGetCallback = (dispatch: Dispatch<PhListGetDispatchType>) => Promise<boolean>;
