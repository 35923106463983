import { CropsT } from '../../reducers/StaticReducer/StaticReducerType';

export const CROPS_GET_LOADING = 'CROPS_GET_LOADING';
export const CROPS_GET_SUCCESS = 'CROPS_GET_SUCCESS';
export const CROPS_GET_FAILED = 'CROPS_GET_FAILED';

export type CropsGetSuccessDataT = CropsT;

export type CropsGetFailedDataT = {
    message?: string;
    list?: string[];
};

export type CropsGetLoadingT = {
    type: typeof CROPS_GET_LOADING;
};

export type CropsGetSuccesT = {
    type: typeof CROPS_GET_SUCCESS;
    payload: CropsGetSuccessDataT;
};

export type CropsGetFailedT = {
    type: typeof CROPS_GET_FAILED;
    error: CropsGetFailedDataT;
};

export type CropsGetDispatchTypes = CropsGetLoadingT | CropsGetSuccesT | CropsGetFailedT;
