import { FarmActionsT, FarmT } from '../../actions/FarmActions/FarmActionsTypes';
import { FarmSeasonUpdateDispatchTypes } from '../../actions/FarmSeasonActions/FarmSeasonUpdateTypes';
import { UserT } from '../../reducers/AuthReducer/AuthReducerTypes';
import { FarmSeasonT } from '../../reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const SUBSCRIPTION_SAVE_FARM_LOADING = 'SUBSCRIPTION_SAVE_FARM_LOADING';
export const SUBSCRIPTION_SAVE_FARM_SUCCESS = 'SUBSCRIPTION_SAVE_FARM_SUCCESS';
export const SUBSCRIPTION_SAVE_FARM_FAILED = 'SUBSCRIPTION_SAVE_FARM_FAILED';

export type SubscriptionSaveFarmDataT = {
    name: string;
    address: string;
    postal_code: string;
    city: string;
    country_id: number;
    phone_number: string;
    eligibility_area: number;
    eligibility_has_livestock: boolean;
    farmId: number | null;
    farmSeasonId: number | null;
};

export type SubscriptionSaveFarmSuccessDataT = {
    farm: FarmT;
    farmSeason: FarmSeasonT;
    user: UserT;
    eligibility_status: string;
};

export type SubscriptionFailedDataT = {
    error: any; // eslint-disable-line
    data?: SubscriptionSaveFarmSuccessDataT;
};

export type SubscriptionSaveFarmActionCallback = (
    dispatch: Dispatch<SubscriptionSaveFarmActionsT | FarmActionsT | FarmSeasonUpdateDispatchTypes>,
) => Promise<SubscriptionFailedDataT>;

export type SubscriptionSaveFarmLoadingT = {
    type: typeof SUBSCRIPTION_SAVE_FARM_LOADING;
};

export type SubscriptionSaveFarmSuccessT = {
    type: typeof SUBSCRIPTION_SAVE_FARM_SUCCESS;
    payload: SubscriptionSaveFarmSuccessDataT;
};

export type SubscriptionSaveFarmFailedT = {
    type: typeof SUBSCRIPTION_SAVE_FARM_FAILED;
    error: SubscriptionFailedDataT;
};

export type SubscriptionSaveFarmActionsT =
    | SubscriptionSaveFarmLoadingT
    | SubscriptionSaveFarmSuccessT
    | SubscriptionSaveFarmFailedT;
