import {
    FarmSeasonFieldT,
    FarmSeasonFieldTillagePracticeHistoryT,
} from '../../reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_LOADING =
    'POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_LOADING';
export const POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_FAILED =
    'POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_FAILED';
export const POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_SUCCESS =
    'POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_SUCCESS';

export type PostFarmSeasonFieldsTillagePracticeHistoryDataT = {
    tillagePracticeHistories: FarmSeasonFieldTillagePracticeHistoryT[];
    dontHaveAny: boolean;
};

export type PostFarmSeasonFieldsTillagePracticeHistoryResponseDataT = {
    fields: FarmSeasonFieldT[];
    installation: {
        onboarding: {
            finished: string[];
            irrigation: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsTillagePracticeHistoryPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsTillagePracticeHistoryDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsTillagePracticeHistoryResponseErrorT>;

export type PostFarmSeasonFieldsTillagePracticeHistoryResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsTillagePracticeHistoryLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_LOADING;
};

export type PostFarmSeasonFieldsTillagePracticeHistoryFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_FAILED;
    error: PostFarmSeasonFieldsTillagePracticeHistoryResponseErrorT;
};

export type PostFarmSeasonFieldsTillagePracticeHistorySuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_SUCCESS;
    payload: PostFarmSeasonFieldsTillagePracticeHistoryResponseDataT;
};

export type PostFarmSeasonFieldsTillagePracticeHistoryDispatchTypesT =
    | PostFarmSeasonFieldsTillagePracticeHistoryLoadingT
    | PostFarmSeasonFieldsTillagePracticeHistoryFailedT
    | PostFarmSeasonFieldsTillagePracticeHistorySuccessT;
