import { FarmSeasonT } from '../../reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { AppActionsT } from '../AppActions/AppActionsTypes';

export const FARM_SEASON_ALL_LOADING = 'FARM_SEASON_ALL_LOADING';
export const FARM_SEASON_ALL_SUCCESS = 'FARM_SEASON_ALL_SUCCESS';
export const FARM_SEASON_ALL_FAILED = 'FARM_SEASON_ALL_FAILED';

export type FarmSeasonGetAllSuccessDataT = FarmSeasonT[];

export type FarmSeasonGetAllFailedDataT = {
    message?: string;
    list?: string[];
};

export type FarmSeasonGetAllCallback = (dispatch: Dispatch<AppActionsT>) => Promise<FarmSeasonT[] | false>;

export type FarmSeasonGetAllLoadingT = {
    type: typeof FARM_SEASON_ALL_LOADING;
};
export type FarmSeasonGetAllSuccessT = {
    type: typeof FARM_SEASON_ALL_SUCCESS;
    payload: FarmSeasonGetAllSuccessDataT;
};

export type FarmSeasonGetAllFailedT = {
    type: typeof FARM_SEASON_ALL_FAILED;
    error: FarmSeasonGetAllFailedDataT;
};

export type FarmSeasonGetAllDispatchTypes =
    | FarmSeasonGetAllLoadingT
    | FarmSeasonGetAllSuccessT
    | FarmSeasonGetAllFailedT;
