import { LOGIN_SUCCESS } from '../../actions/AuthActions/LoginActionsTypes';
import { LOGOUT_SUCCESS } from '../../actions/AuthActions/LogoutActionsTypes';
import { SIGNUP_SUCCESS } from '../../actions/AuthActions/SignUpActionsTypes';
import { AppActionsT } from '../../actions/AppActions/AppActionsTypes';
import { Reducer } from '@reduxjs/toolkit';

import { RoutingReducerStateT } from './RoutingReducerTypes';

export const defaultRoutingState: RoutingReducerStateT = {};

const routingReducer: Reducer<RoutingReducerStateT, AppActionsT> = (state, action): RoutingReducerStateT => {
    if (!state) {
        return defaultRoutingState;
    }

    switch (action.type) {
        case 'ROUTING_REDIRECT':
            return {
                redirectTo: action.payload.route,
            };
        case LOGIN_SUCCESS:
            return {
                redirectTo: '/',
            };
        case SIGNUP_SUCCESS:
            return {
                redirectTo: '/subscribe',
            };
        case LOGOUT_SUCCESS:
            return {
                redirectTo: '/login',
            };
        default:
            return state;
    }
};

export default routingReducer;
