import { Divider } from '@mui/material';
import { Typography } from '@soil-capital/ui-kit/material-core';
import useDividerTextStyle from './DividerText.style';

export enum DIVIDER_TEXT_ALIGNMENT {
    LEFT,
    CENTER,
    RIGHT,
}

export type DividerTextPropsT = {
    text?: string;
    textAlign?: DIVIDER_TEXT_ALIGNMENT;
};

export const DividerText = (props: DividerTextPropsT): JSX.Element => {
    const { classes } = useDividerTextStyle();

    return (
        <div className={`${classes.dividerContainer}`}>
            {props.textAlign === DIVIDER_TEXT_ALIGNMENT.CENTER ||
                (props.textAlign === DIVIDER_TEXT_ALIGNMENT.RIGHT && <Divider />)}
            {props.text && (
                <Typography className={classes.dividerText} component="span">
                    {props.text}
                </Typography>
            )}
            {props.textAlign === DIVIDER_TEXT_ALIGNMENT.CENTER ||
                (props.textAlign === DIVIDER_TEXT_ALIGNMENT.LEFT && <Divider />)}
        </div>
    );
};
