import staticLanguage from '@shared/entities/staticLanguage';
import { SharedStateT } from '@shared/store';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import authApi from '../api/authApi';
import { LoginRespT } from '../api/authApi.types';
import authSelectors from './authSelectors';
import authSlice from './authSlice';
import { removeHasAccessTokenCookie } from '@modules/auth/utils/getHasAccessTokenCookie';

const useLogout = () => {
    const [logoutMutation, { isLoading }] = authApi.useLogoutMutation();
    // const { storeAuthData } = _useStoreAuthData();
    // const dispatch = useDispatch();

    const logout = async () => {
        const logoutPromise = logoutMutation();
        // make sure we already remove the cookie even if the BE logout fail.
        removeHasAccessTokenCookie();
        await logoutPromise;

        // temporary redirect to v2 login page. The state reset has been commented
        window.location.href = `${process.env.REACT_APP_URL_V2}/login` || '';
        // // reset the v3 auth state
        // storeAuthData(null);
        // // reset the legacy state. Should we reset the whole state?
        // dispatch({ type: RESET });
    };

    return { logout, logoutLoading: isLoading };
};

const useRegister = () => {
    const { storeAuthData } = _useStoreAuthData();
    const [isLoading, setIsLoading] = useState(false);
    const [registerMutation] = authApi.useRegisterMutation();
    const [lazyGetPermissionsQuery] = authApi.useLazyGetPermissionsQuery();

    const register = async (...p: Parameters<typeof registerMutation>) => {
        setIsLoading(true);
        try {
            const registerResponse = await registerMutation(...p).unwrap();
            await lazyGetPermissionsQuery();
            storeAuthData(registerResponse);
            setIsLoading(false);
            return registerResponse;
        } catch (e) {
            setIsLoading(false);
            throw e;
        }
    };

    return {
        register,
        registerLoading: isLoading,
    };
};

const useLogin = () => {
    const { storeAuthData } = _useStoreAuthData();
    const [isLoading, setIsLoading] = useState(false);
    const [loginMutation] = authApi.useLoginMutation();
    const [lazyGetPermissionsQuery] = authApi.useLazyGetPermissionsQuery();
    const redirectionAfterAuthentication = authSelectors.useUrlToRedirect();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const login = async (...p: Parameters<typeof loginMutation>) => {
        setIsLoading(true);
        try {
            const loginResponse = await loginMutation(...p).unwrap();
            await lazyGetPermissionsQuery();
            storeAuthData(loginResponse);
            if (redirectionAfterAuthentication) {
                navigate(redirectionAfterAuthentication);
                dispatch(authSlice.actions.keepUrlToRedirect(null));
            }
            setIsLoading(false);
            return loginResponse;
        } catch (e) {
            setIsLoading(false);
            throw e;
        }
    };

    return {
        login,
        loginLoading: isLoading,
    };
};

const useValidateCookie = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { storeAuthData } = _useStoreAuthData();
    const [lazyValidateCookieQuery] = authApi.useLazyValidateCookieQuery();
    const [lazyGetPermissionsQuery] = authApi.useLazyGetPermissionsQuery();

    const redirectionAfterAuthentication = authSelectors.useUrlToRedirect();
    const redirectionAfterLoginRef = useRef(redirectionAfterAuthentication);
    redirectionAfterLoginRef.current = redirectionAfterAuthentication;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateCookie = async (...p: Parameters<typeof lazyValidateCookieQuery>) => {
        setIsLoading(true);
        try {
            const validateResponse = await lazyValidateCookieQuery(...p).unwrap();
            storeAuthData(validateResponse);

            await lazyGetPermissionsQuery();

            if (redirectionAfterLoginRef.current) {
                navigate(redirectionAfterLoginRef.current);
                dispatch(authSlice.actions.keepUrlToRedirect(null));
            }

            setIsLoading(false);
            return validateResponse;
        } catch (e: unknown) {
            setIsLoading(false);
            //if (e.status === 401) {
            // TODO Cookie has_access_token should be deleted? Should we call storeAuthData(null)? This behavior should stand for all 401 in the app.
            //}
            throw e; // Rethrow the error so the calling code can handle it if needed
        }
    };

    return {
        validateCookie,
        validateCookieLoading: isLoading,
    };
};

const useChangeLanguage = () => {
    const dispatch = useDispatch();
    const { staticLanguageState } = staticLanguage.useState();
    const [changeLanguageMutation, { isLoading: changeLanguageLoading }] = authApi.useChangeLanguageMutation();
    const changeLanguage = ({ isoCode }: { isoCode: string }) => {
        const newLanguage = staticLanguageState.getByIsoCode(isoCode);
        if (!newLanguage?.id) {
            throw Error('language not found');
        }
        changeLanguageMutation({ id: newLanguage.id })
            .unwrap()
            .then((r) => {
                dispatch(authSlice.actions.mergeAuthUser({ language_id: r.language_id }));
                return r;
            });
    };

    return {
        changeLanguage,
        changeLanguageLoading,
    };
};

const _useStoreAuthData = () => {
    const dispatch = useDispatch();
    return {
        storeAuthData: (data: LoginRespT | null): void => {
            // handle logout state data
            if (data === null) {
                dispatch(authSlice.actions.setAuthUser(null));
                return;
            }

            // handle authenticate state data
            const authUserData = {
                ...data.user,
            };
            const farmerInfos = data.farmer_infos
                ? {
                      latestActiveSeasonId: data.farmer_infos?.latest_active_season ?? null,
                      modelType: data.farmer_infos?.model_type ?? null,
                  }
                : null;
            dispatch(authSlice.actions.setAuthUser(authUserData));
            dispatch(authSlice.actions.setFarmerInfos(farmerInfos));
        },
    };
};

const useUpdateFarmerInfos = () => {
    const dispatch = useDispatch();
    const farmerInfos = useSelector((state: SharedStateT) => state.auth.farmerInfos);

    const updateFarmerInfos = ({ latestActiveSeason }: { latestActiveSeason: number }) => {
        const newFarmerInfos = {
            modelType: farmerInfos?.modelType ?? null,
            latestActiveSeasonId: latestActiveSeason,
        };
        dispatch(authSlice.actions.setFarmerInfos(newFarmerInfos));
    };
    return { updateFarmerInfos };
};

/** keep the url pathname (and query params) so we can redirect after an authentication */
const useKeepUrlToRedirect = (isAuthenticated: boolean) => {
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        if (!isAuthenticated) {
            dispatch(authSlice.actions.keepUrlToRedirect(location));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default {
    useChangeLanguage,
    useLogout,
    useLogin,
    useRegister,
    useValidateCookie,
    useKeepUrlToRedirect,
    useUpdateFarmerInfos,
};
