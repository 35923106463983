import {
    AppActionsT,
    APP_SET_IS_FIRST_MENU_ITEM_SELECTED,
    APP_NAVIGATE_PROMPT_MESSAGE,
    APP_SIMULATION_TOOL_TOGGLE_MODAL,
} from '../../actions/AppActions/AppActionsTypes';
import { RESET } from '../../reducers/RootTypes';
import { Reducer } from '@reduxjs/toolkit';

import { AppReducerStateT } from './AppReducerTypes';

export const defaultAppState: AppReducerStateT = {
    isFirstMenuItemSelected: false,
    navigatePromptMessage: null,
    initFarmsAndSeasonsLoading: false,
    simulationModalOpen: false,
    pendingStatesForHomePageLayout: {},
    permissions: null,
    permissionsLoading: false,
    modalWarningProps: null,
    modalWarningController: null,
};

const AppReducer: Reducer<AppReducerStateT, AppActionsT> = (state, action): AppReducerStateT => {
    if (!state) {
        return defaultAppState;
    }

    switch (action.type) {
        case APP_SET_IS_FIRST_MENU_ITEM_SELECTED: {
            return { ...state, isFirstMenuItemSelected: action.payload.isFirstMenuItemSelected };
        }

        case APP_NAVIGATE_PROMPT_MESSAGE: {
            return { ...state, navigatePromptMessage: action.payload.navigatePromptMessage };
        }

        case 'APP_INIT_FARMS_AND_SEASONS_LOADING': {
            return { ...state, initFarmsAndSeasonsLoading: action.payload.initFarmsAndSeasonsLoading };
        }

        case APP_SIMULATION_TOOL_TOGGLE_MODAL:
            return { ...state, simulationModalOpen: !state.simulationModalOpen };

        case 'APP_ADD_PENDING_STATE_FOR_HOME_PAGE_LAYOUT': {
            if (action.payload.pendingStateForHomePageLayout.pending === false) {
                return state;
            }
            const newPendingStates = {
                ...state.pendingStatesForHomePageLayout,
                [action.payload.pendingStateForHomePageLayout.slug]:
                    action.payload.pendingStateForHomePageLayout.pending,
            };
            return { ...state, pendingStatesForHomePageLayout: newPendingStates };
        }

        case 'APP_REMOVE_PENDING_STATE_FOR_HOME_PAGE_LAYOUT': {
            const newPendingStates = {
                ...state.pendingStatesForHomePageLayout,
            };
            delete newPendingStates[action.payload.pendingSlugForHomePageLayout];
            return { ...state, pendingStatesForHomePageLayout: newPendingStates };
        }

        case 'APP_SET_PERMISSIONS': {
            return { ...state, permissions: action.payload.permissions };
        }

        case 'APP_SET_PERMISSIONS_LOADING': {
            return { ...state, permissionsLoading: action.payload.loading };
        }

        case 'APP_SET_MODAL_WARNING_PROPS': {
            return { ...state, modalWarningProps: action.payload.modalWarningProps };
        }

        case 'APP_INIT_MODAL_WARNING_CONTROLLER': {
            return { ...state, modalWarningController: action.payload.modalWarningController };
        }

        case RESET: {
            return { ...defaultAppState };
        }

        default: {
            return state;
        }
    }
};

export default AppReducer;
