import { FarmSeasonFieldCropDataT } from '../../reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_TILLAGE_LOADING = 'POST_FARM_SEASON_FIELDS_TILLAGE_LOADING';
export const POST_FARM_SEASON_FIELDS_TILLAGE_FAILED = 'POST_FARM_SEASON_FIELDS_TILLAGE_FAILED';
export const POST_FARM_SEASON_FIELDS_TILLAGE_SUCCESS = 'POST_FARM_SEASON_FIELDS_TILLAGE_SUCCESS';

export type FarmSeasonFieldsTillageT = {
    id?: number;
    name: string;
    area: number;
    field_crops?: FarmSeasonFieldCropDataT[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    polygon: any;
    tillage_id: number | null;
    is_tillage_permanent?: boolean | null;
};

export type PostFarmSeasonFieldsTillageDataT = {
    fields: FarmSeasonFieldsTillageT[];
};

export type PostFarmSeasonFieldsTillageResponseDataT = {
    fields: FarmSeasonFieldsTillageT[];
    installation: {
        onboarding: {
            finished: string[];
            tillage: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsTillagePostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsTillageDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsTillageResponseErrorT>;

export type PostFarmSeasonFieldsTillageResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsTillageLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_TILLAGE_LOADING;
};

export type PostFarmSeasonFieldsTillageFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_TILLAGE_FAILED;
    error: PostFarmSeasonFieldsTillageResponseErrorT;
};

export type PostFarmSeasonFieldsTillageSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_TILLAGE_SUCCESS;
    payload: PostFarmSeasonFieldsTillageResponseDataT;
};

export type PostFarmSeasonFieldsTillageDispatchTypesT =
    | PostFarmSeasonFieldsTillageLoadingT
    | PostFarmSeasonFieldsTillageFailedT
    | PostFarmSeasonFieldsTillageSuccessT;
