import { FarmSeasonT } from '../../reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import { FarmSeasonFieldT } from '../../reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_INSTALLATION_CONFIRM_LOADING = 'POST_FARM_SEASON_INSTALLATION_CONFIRM_LOADING';
export const POST_FARM_SEASON_INSTALLATION_CONFIRM_FAILED = 'POST_FARM_SEASON_INSTALLATION_CONFIRM_FAILED';
export const POST_FARM_SEASON_INSTALLATION_CONFIRM_SUCCESS = 'POST_FARM_SEASON_INSTALLATION_CONFIRM_SUCCESS';

export type TasksT = {
    agroforestry?: boolean;
    coverCropHistory?: boolean;
    crop?: boolean;
    drainage?: boolean;
    field?: boolean;
    irrigation?: boolean;
    livestock?: boolean;
    organic?: boolean;
    coverCrop?: boolean;
    soilAcidity?: boolean;
    soilOrganicMatter?: boolean;
    tillage?: boolean;
    organicFertilizationHistory?: boolean;
};

export type PostFarmSeasonInstallationConfirmDataT = {
    tasks: TasksT;
};

export type PostFarmSeasonInstallationConfirmResponseDataT = {
    farm_season: FarmSeasonT;
    farm_season_fields: FarmSeasonFieldT[];
};

export type FarmSeasonInstallationConfirmPostCallBackT = (
    dispatch: Dispatch<FarmSeasonPostInstallationConfirmDispatchTypesT>,
) => Promise<PostFarmSeasonInstallationConfirmResponseErrorT>;

export type PostFarmSeasonInstallationConfirmResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonInstallationConfirmLoadingT = {
    type: typeof POST_FARM_SEASON_INSTALLATION_CONFIRM_LOADING;
};

export type PostFarmSeasonInstallationConfirmFailedT = {
    type: typeof POST_FARM_SEASON_INSTALLATION_CONFIRM_FAILED;
    error: PostFarmSeasonInstallationConfirmResponseErrorT;
};

export type PostFarmSeasonInstallationConfirmSuccessT = {
    type: typeof POST_FARM_SEASON_INSTALLATION_CONFIRM_SUCCESS;
    payload: PostFarmSeasonInstallationConfirmResponseDataT;
};

export type FarmSeasonPostInstallationConfirmDispatchTypesT =
    | PostFarmSeasonInstallationConfirmLoadingT
    | PostFarmSeasonInstallationConfirmFailedT
    | PostFarmSeasonInstallationConfirmSuccessT;
