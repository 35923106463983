import { FormControlLabel, FormHelperText } from '@mui/material';
import { Checkbox } from '@soil-capital/ui-kit/components';
import HelpButtonView from '../HelpButton/HelpButtonView';
import TooltipSc from '../TooltipSc/TooltipSc';

import useLabelCheckboxStyle from './LabelCheckboxView.style';
import { LabelCheckboxViewPropsT } from './LabelCheckboxViewTypes';

const LabelCheckboxView = ({
    label,
    onChange,
    checked,
    name,
    error,
    helperText,
    className,
    noMargin,
    border = 'none',
    disabled,
    withHelp,
    containerClass,
    id,
    helpName,
    tooltipSlug,
}: LabelCheckboxViewPropsT): JSX.Element => {
    const { classes } = useLabelCheckboxStyle({ border, noMargin });

    return (
        <div className={`${classes.checkboxContainer} ${containerClass}`}>
            <FormControlLabel
                className={`${classes.label} ${className}`}
                control={<Checkbox checked={checked} className={classes.checkbox} name={name} onChange={onChange} />}
                disabled={disabled}
                label={
                    <>
                        <TooltipSc translationSlug={tooltipSlug}>{label}</TooltipSc>
                        {withHelp && <HelpButtonView id={helpName || `${id}-help`} />}
                    </>
                }
                name={name}
            />
            {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </div>
    );
};

export default LabelCheckboxView;
