import { GetFarmSeasonCropsDispatchTypesT } from '../../actions/FarmSeasonCropActions/GetFarmSeasonCropsTypes';
import { FarmSeasonFieldT } from '../../reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_LOADING = 'POST_FARM_SEASON_FIELDS_LOADING';
export const POST_FARM_SEASON_FIELDS_FAILED = 'POST_FARM_SEASON_FIELDS_FAILED';
export const POST_FARM_SEASON_FIELDS_SUCCESS = 'POST_FARM_SEASON_FIELDS_SUCCESS';

export type PostFarmSeasonFieldDataT = {
    id?: number;
    original_area: number;
    user_area: number | null;
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    polygon: any; // geoJsonFeatureT;
    shapefileCrop?: number | string;
    shapefileCountry?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    area_source?: any; // areaSourcesT;
};

export type PostFarmSeasonFieldsDataT = {
    fields: Array<PostFarmSeasonFieldDataT> | false;
};

export type PostFarmSeasonFieldsResponseDataT = {
    fields?: FarmSeasonFieldT[];
    installation: {
        onboarding: {
            finished: string[];
            field: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsDispatchTypesT | GetFarmSeasonCropsDispatchTypesT>,
) => Promise<boolean>;

export type PostFarmSeasonFieldsResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_LOADING;
};

export type PostFarmSeasonFieldsFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_FAILED;
    error: PostFarmSeasonFieldsResponseErrorT;
};

export type PostFarmSeasonFieldsSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_SUCCESS;
    payload: PostFarmSeasonFieldsResponseDataT;
};

export type PostFarmSeasonFieldsDispatchTypesT =
    | PostFarmSeasonFieldsLoadingT
    | PostFarmSeasonFieldsFailedT
    | PostFarmSeasonFieldsSuccessT;
