import { IrrigationWaterSourceT } from '../../reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const IRRIGATION_WATER_SOURCE_LIST_GET_LOADING = 'IRRIGATION_WATER_SOURCE_LIST_GET_LOADING';
export const IRRIGATION_WATER_SOURCE_LIST_GET_SUCCESS = 'IRRIGATION_WATER_SOURCE_LIST_GET_SUCCESS';
export const IRRIGATION_WATER_SOURCE_LIST_GET_FAILED = 'IRRIGATION_WATER_SOURCE_LIST_GET_FAILED';

export type IrrigationWaterSourceListGetSuccessDataT = IrrigationWaterSourceT[];

export type IrrigationWaterSourceListGetLoadingT = {
    type: typeof IRRIGATION_WATER_SOURCE_LIST_GET_LOADING;
};

export type IrrigationWaterSourceListGetSuccessT = {
    type: typeof IRRIGATION_WATER_SOURCE_LIST_GET_SUCCESS;
    payload: IrrigationWaterSourceListGetSuccessDataT;
};

export type IrrigationWaterSourceListGetFailedT = {
    type: typeof IRRIGATION_WATER_SOURCE_LIST_GET_FAILED;
    error: string;
};

export type IrrigationWaterSourceListGetDispatchType =
    | IrrigationWaterSourceListGetLoadingT
    | IrrigationWaterSourceListGetSuccessT
    | IrrigationWaterSourceListGetFailedT;

export type IrrigationWaterSourceListGetCallback = (
    dispatch: Dispatch<IrrigationWaterSourceListGetDispatchType>,
) => Promise<boolean>;
