import { DrainageT } from '../../reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const DRAINAGE_LIST_GET_LOADING = 'DRAINAGE_LIST_GET_LOADING';
export const DRAINAGE_LIST_GET_SUCCESS = 'DRAINAGE_LIST_GET_SUCCESS';
export const DRAINAGE_LIST_GET_FAILED = 'DRAINAGE_LIST_GET_FAILED';

export type DrainageListGetSuccessDataT = DrainageT[];

export type DrainageListGetLoadingT = {
    type: typeof DRAINAGE_LIST_GET_LOADING;
};

export type DrainageListGetSuccessT = {
    type: typeof DRAINAGE_LIST_GET_SUCCESS;
    payload: DrainageListGetSuccessDataT;
};

export type DrainageListGetFailedT = {
    type: typeof DRAINAGE_LIST_GET_FAILED;
    error: string;
};

export type DrainageListGetDispatchType = DrainageListGetLoadingT | DrainageListGetSuccessT | DrainageListGetFailedT;

export type DrainageListGetCallback = (dispatch: Dispatch<DrainageListGetDispatchType>) => Promise<boolean>;
