import { AppReducerStateT } from '../../reducers/AppReducer/AppReducerTypes';
import { GetResetT } from '../../reducers/RootTypes';
import { AuthActionsT } from '../AuthActions/AuthActionTypes';
import { ErrorActionsT } from '../ErrorActions/ErrorActionsTypes';
import { FarmActionsT } from '../FarmActions/FarmActionsTypes';
import { FarmSeasonActionsT } from '../FarmSeasonActions/FarmSeasonActionsTypes';
import { FarmSeasonCropActionsT } from '../FarmSeasonCropActions/FarmSeasonCropActionsTypes';
import { FarmSeasonFieldActionsT } from '../FarmSeasonFieldActions/FarmSeasonFieldActionsTypes';
import { FarmSeasonFieldCropActionsT } from '../FarmSeasonFieldCropActions/FarmSeasonFieldCropActionsTypes';
import { FarmSeasonLivestockActionsT } from '../FarmSeasonLivestockActions/FarmSeasonLivestockActionsTypes';
import { FarmSeasonOperationActionsT } from '../FarmSeasonOperation/FarmSeasonOperationActionsTypes';
import { NotificationActionsT } from '../NotificationActions/NotificationActionsTypes';
import { ProductPlanDispatchTypes } from '../ProductPlanActions/ProductPlanActions';
import { RoutingActionsT } from '../RoutingActions/RoutingActionsTypes';
import { StaticActionsT } from '../StaticActions/StaticActionsTypes';
import { SubscriptionActionsT } from '../SubscriptionActions/SubscriptionActionsTypes';
import { AppSetPermissions, AppSetPermissionsLoading } from './GetPermissionsTypes';
import { BillingDetailsDispatchesT } from '../BillingDetailsActions/BillingDetailsActions.types';

type ModalWarningPropsT = any;
export type HomePageLayoutPendingStateT = {
    slug: string;
    pending: Promise<unknown> | boolean;
};

export const APP_SET_IS_FIRST_MENU_ITEM_SELECTED = 'APP_SET_IS_FIRST_MENU_ITEM_SELECTED';

export type SetIsFirstMenuItemSelectedActionT = {
    type: typeof APP_SET_IS_FIRST_MENU_ITEM_SELECTED;
    payload: {
        isFirstMenuItemSelected: boolean;
    };
};

export const APP_NAVIGATE_PROMPT_MESSAGE = 'APP_NAVIGATE_PROMPT_MESSAGE';

export type SetNavigatePromptMessageActionT = {
    type: typeof APP_NAVIGATE_PROMPT_MESSAGE;
    payload: {
        navigatePromptMessage: null | { title: string; text: string };
    };
};

export const APP_SIMULATION_TOOL_TOGGLE_MODAL = 'APP_SIMULATION_TOOL_TOGGLE_MODAL';

export type SimulationToolToggleModalT = {
    type: typeof APP_SIMULATION_TOOL_TOGGLE_MODAL;
};

export type APP_INIT_FARMS_AND_SEASONS_LOADING = 'APP_INIT_FARMS_AND_SEASONS_LOADING';
export type AppInitDataForAuthUserLoadingT = {
    type: APP_INIT_FARMS_AND_SEASONS_LOADING;
    payload: {
        initFarmsAndSeasonsLoading: boolean;
    };
};

export type APP_ADD_PENDING_STATE_FOR_HOME_PAGE_LAYOUT = 'APP_ADD_PENDING_STATE_FOR_HOME_PAGE_LAYOUT';
export type AppAddPendingStateForHomePageLayout = {
    type: APP_ADD_PENDING_STATE_FOR_HOME_PAGE_LAYOUT;
    payload: {
        pendingStateForHomePageLayout: HomePageLayoutPendingStateT;
    };
};
export type APP_REMOVE_PENDING_STATE_FOR_HOME_PAGE_LAYOUT = 'APP_REMOVE_PENDING_STATE_FOR_HOME_PAGE_LAYOUT';
export type AppResetPendingStateForHomePageLayout = {
    type: APP_REMOVE_PENDING_STATE_FOR_HOME_PAGE_LAYOUT;
    payload: { pendingSlugForHomePageLayout: string };
};

export type APP_SET_MODAL_WARNING_PROPS = 'APP_SET_MODAL_WARNING_PROPS';
export type AppSetModalWarningProps = {
    type: APP_SET_MODAL_WARNING_PROPS;
    payload: {
        modalWarningProps: null | Pick<
            ModalWarningPropsT,
            'title' | 'rightButtonText' | 'leftButtonText' | 'description' | 'confirmMessage'
        >;
    };
};
export type APP_INIT_MODAL_WARNING_CONTROLLER = 'APP_INIT_MODAL_WARNING_CONTROLLER';
export type AppSetModalWarningController = {
    type: APP_INIT_MODAL_WARNING_CONTROLLER;
    payload: { modalWarningController: AppReducerStateT['modalWarningController'] };
};

export type AppActionsT =
    | AuthActionsT
    | ErrorActionsT
    | FarmActionsT
    | FarmSeasonActionsT
    | FarmSeasonCropActionsT
    | FarmSeasonFieldActionsT
    | FarmSeasonFieldCropActionsT
    | FarmSeasonLivestockActionsT
    | FarmSeasonOperationActionsT
    | NotificationActionsT
    | SubscriptionActionsT
    | StaticActionsT
    | RoutingActionsT
    // next to remove:
    | AppInitDataForAuthUserLoadingT
    | AppAddPendingStateForHomePageLayout
    | AppResetPendingStateForHomePageLayout
    | AppSetPermissions
    | AppSetPermissionsLoading
    | SetIsFirstMenuItemSelectedActionT
    | SetNavigatePromptMessageActionT
    | SimulationToolToggleModalT
    | GetResetT
    | ProductPlanDispatchTypes
    | AppSetModalWarningProps
    | AppSetModalWarningController
    | BillingDetailsDispatchesT;
