import { TillageT } from '../../reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const TILLAGE_LIST_GET_LOADING = 'TILLAGE_LIST_GET_LOADING';
export const TILLAGE_LIST_GET_SUCCESS = 'TILLAGE_LIST_GET_SUCCESS';
export const TILLAGE_LIST_GET_FAILED = 'TILLAGE_LIST_GET_FAILED';

export type TillageListGetSuccessDataT = TillageT[];

export type TillageListGetLoadingT = {
    type: typeof TILLAGE_LIST_GET_LOADING;
};

export type TillageListGetSuccessT = {
    type: typeof TILLAGE_LIST_GET_SUCCESS;
    payload: TillageListGetSuccessDataT;
};

export type TillageListGetFailedT = {
    type: typeof TILLAGE_LIST_GET_FAILED;
    error: string;
};

export type TillageListGetDispatchType = TillageListGetLoadingT | TillageListGetSuccessT | TillageListGetFailedT;

export type TillageListGetCallback = (dispatch: Dispatch<TillageListGetDispatchType>) => Promise<boolean>;
