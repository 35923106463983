import { NotificationT } from '../../reducers/NotificationReducer/NotificationReducerTypes';

export enum NotificationActionTypes {
    MARK_ALL_AS_READ = 'MARK_ALL_AS_READ_NOTIFICATION',
    MARK_ALL_AS_READ_SUCCESS = 'MARK_ALL_AS_READ_SUCCESS_NOTIFICATION',
    MARK_ALL_AS_READ_FAIL = 'MARK_ALL_AS_READ_FAIL_NOTIFICATION',

    MARK_AS_READ = 'MARK_AS_READ_NOTIFICATION',
    MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS_NOTIFICATION',
    MARK_AS_READ_FAIL = 'MARK_AS_READ_FAIL_NOTIFICATION',

    FETCH = 'FETCH_NOTIFICATION',
    FETCH_SUCCESS = 'FETCH_SUCCESS_NOTIFICATION',
    FETCH_FAIL = 'FETCH_FAIL_NOTIFICATION',

    FETCH_MORE = 'FETCH_MORE_NOTIFICATION',
    FETCH_MORE_SUCCESS = 'FETCH_MORE_SUCCESS_NOTIFICATION',
    FETCH_MORE_FAIL = 'FETCH_MORE_FAIL_NOTIFICATION',

    FETCH_SILENT_SUCCESS = 'FETCH_SILENT_SUCCESS_NOTIFICATION',
    SHIFT_SNACKBAR_STACK = 'POP_SNACKBAR_STACK_NOTIFICATION',
}

/* ------------------------- Mark as read dispatches ------------------------ */
type NotificationMarkAsReadDispatch = {
    type: NotificationActionTypes.MARK_AS_READ;
    payload: { id: string };
};
type NotificationMarkAsReadDispatchSuccess = {
    type: NotificationActionTypes.MARK_AS_READ_SUCCESS;
    payload: { notification: NotificationT };
};
type NotificationMarkAsReadDispatchFail = {
    type: NotificationActionTypes.MARK_AS_READ_FAIL;
    payload: { id: string };
};
/* -------------------------------------------------------------------------- */

/* ----------------------- Mark all as read dispatches ---------------------- */
type NotificationMarkAllAsReadDispatch = {
    type: NotificationActionTypes.MARK_ALL_AS_READ;
    payload: null;
};
type NotificationMarkAllAsReadDispatchSuccess = {
    type: NotificationActionTypes.MARK_ALL_AS_READ_SUCCESS;
    payload: null;
};
type NotificationMarkAllAsReadDispatchFail = {
    type: NotificationActionTypes.MARK_ALL_AS_READ_FAIL;
    payload: null;
};
/* -------------------------------------------------------------------------- */

/* ---------------------------- Fetch dispatches ---------------------------- */
type NotificationFetchDispatch = {
    type: NotificationActionTypes.FETCH;
    payload: null;
};
type NotificationFetchDispatchSuccess = {
    type: NotificationActionTypes.FETCH_SUCCESS;
    payload: { notifications: NotificationT[]; notificationsCount: number; unreadNotificationsCount: number };
};
type NotificationFetchDispatchFail = {
    type: NotificationActionTypes.FETCH_FAIL;
    payload: null;
};
/* -------------------------------------------------------------------------- */

/* -------------------------- Fetch more dispatches ------------------------- */
type NotificationFetchMoreDispatch = {
    type: NotificationActionTypes.FETCH_MORE;
    payload: null;
};
type NotificationFetchMoreDispatchSuccess = {
    type: NotificationActionTypes.FETCH_MORE_SUCCESS;
    payload: { notifications: NotificationT[]; notificationsCount: number; unreadNotificationsCount: number };
};
type NotificationFetchMoreDispatchFail = {
    type: NotificationActionTypes.FETCH_MORE_FAIL;
    payload: null;
};
/* -------------------------------------------------------------------------- */

/* -------------------------- Fetch Sync dispatches ------------------------- */
type NotificationFetchSilentDispatchSuccess = {
    type: NotificationActionTypes.FETCH_SILENT_SUCCESS;
    payload: { notifications: NotificationT[]; notificationsCount: number; unreadNotificationsCount: number };
};
/* -------------------------------------------------------------------------- */

type NotificationPopSnackbarStackDispatch = {
    type: NotificationActionTypes.SHIFT_SNACKBAR_STACK;
};

/* -------------------- All dispatches for notifications -------------------- */
export type NotificationActionsT =
    | NotificationMarkAsReadDispatch
    | NotificationMarkAsReadDispatchSuccess
    | NotificationMarkAsReadDispatchFail
    | NotificationMarkAllAsReadDispatch
    | NotificationMarkAllAsReadDispatchSuccess
    | NotificationMarkAllAsReadDispatchFail
    | NotificationFetchDispatch
    | NotificationFetchDispatchSuccess
    | NotificationFetchDispatchFail
    | NotificationFetchMoreDispatch
    | NotificationFetchMoreDispatchSuccess
    | NotificationFetchMoreDispatchFail
    | NotificationFetchSilentDispatchSuccess
    | NotificationPopSnackbarStackDispatch;
