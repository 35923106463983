import { Components, Theme } from '@mui/material';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

const InputBaseCss: ComponentsOverrides<Theme>['MuiInputBase'] = {
    root: () => ({}),
    input: {
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
    },
};

const MuiInputBase: Components<Theme>['MuiInputBase'] = { styleOverrides: InputBaseCss };

export default MuiInputBase;
