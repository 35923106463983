import { makeStyles } from 'tss-react/mui';
import { CSSObject } from 'tss-react';

const useLabelCheckboxViewStyle = makeStyles<{
    border?: 'dotted' | 'none' | 'simple';
    noMargin?: boolean;
}>()((theme, { border, noMargin }) => ({
    label: {
        '&.MuiFormControlLabel-root .MuiTypography-body1': {
            transform: 'translate(0, 1px)',
            ...(theme.typography.deprecated_h6 as CSSObject),
        },
    },
    checkboxContainer: {
        padding: '0 10px',
        border: border === 'dotted' ? `2px dashed ${theme.palette.secondary.main}` : 'none',
        borderRadius: '5px',
        marginBottom: noMargin ? '0px' : '20px',
    },
    checkbox: {
        borderRadius: '5px',
        '& svg': {
            borderColor: theme.palette.secondary.light,
            height: '20px',
            width: '20px',
        },
    },
}));

export default useLabelCheckboxViewStyle;
