import { makeStyles } from 'tss-react/mui';

import { AutocompleteStylePropsT } from './AutocompleteTypes';

const useAutocompleteStyle = makeStyles<AutocompleteStylePropsT>()((theme, { borderColor, disabled }) => ({
    root: {
        '& .MuiInputBase-root fieldset': {
            borderColor:
                // eslint-disable-next-line no-nested-ternary
                disabled ? 'rgba(0, 0, 0, 0.26)' : borderColor === 'grey' ? theme.palette.grey['300'] : undefined,
        },
    },

    iconContainerStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '24px',
        height: '24px',
    },

    iconStyle: {
        color: disabled ? 'rgba(0, 0, 0, 0.26)' : theme.palette.secondary.main,
        fontSize: '0.60rem',
    },

    optionCreate: {
        marginTop: '-5px',
        padding: '7px 16px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'end',
        cursor: 'pointer',
        borderBottom: `1px ${' '} solid ${' '} ${theme.palette.divider}`,
        '&>svg': {
            marginRight: '5px',
        },
    },
}));

export default useAutocompleteStyle;
