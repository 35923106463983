import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()(() => {
    return {
        container: {
            padding: '16px',
        },
        title: {
            marginBottom: '16px',
        },
    };
});
