import { Components, Theme } from '@mui/material';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

const avatarCss: ComponentsOverrides<Theme>['MuiAvatar'] = {
    root: {
        boxShadow: '0 0 10px 0px #DDD',
        border: '3px solid #FFF',
    },
};

const MuiAvatar: Components<Theme>['MuiAvatar'] = { styleOverrides: avatarCss };

export default MuiAvatar;
