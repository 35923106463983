import { auth } from '@modules/auth';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetFarmer } from '@shared/hooks/useGetFarmer';
import { useGetFarmPaymentStatusQuery } from './api/paymentApi';
import { useEffect, useState } from 'react';

export const usePaymentLogic = () => {
    // To avoid loading the page when the user is in the confirmation page/processing page
    const hasToLoad =
        !location.pathname.includes('confirmation') &&
        !location.pathname.includes('net-off') &&
        !location.pathname.includes('processing');
    const [isLoading, setIsLoading] = useState(hasToLoad);
    const hasPermission = auth.usePermission('canMakePayment');
    const { currentSeason } = useCurrentSeason();
    const { farmer, isLoading: isFarmerLoading } = useGetFarmer();
    const { data: farmPaymentStatus, isLoading: isFarmPaymentStatusLoading } = useGetFarmPaymentStatusQuery({
        farmId: currentSeason?.farm_id,
    });
    const { data: partnerInfo, isLoading: isPartnerInfoLoading } = useGetPartnerInfoQuery({ userId: farmer?.id });

    useEffect(() => {
        if (
            !isFarmPaymentStatusLoading &&
            !isPartnerInfoLoading &&
            currentSeason &&
            !isFarmerLoading &&
            farmPaymentStatus &&
            farmer
        ) {
            setIsLoading(false);
        }
    }, [isFarmPaymentStatusLoading, isPartnerInfoLoading, currentSeason, isFarmerLoading, farmer, farmPaymentStatus]);

    return {
        isLoading,
        canMakePayment:
            farmPaymentStatus?.status === 'deferred' ||
            farmPaymentStatus?.status === 'to_be_renewed' ||
            farmPaymentStatus?.status === 'net_off',
        isPartnerPrepaying: !!partnerInfo?.is_partner_prepaying,
        hasPermission,
        currentSeasonId: currentSeason?.id,
    };
};
