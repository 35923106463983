import { Components, Theme } from '@mui/material';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

const FormControlLabelCss: ComponentsOverrides<Theme>['MuiFormControlLabel'] = {
    label: () => ({
        fontSize: '0.95rem',
    }),
};

const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = { styleOverrides: FormControlLabelCss };

export default MuiFormControlLabel;
