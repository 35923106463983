import { Divider, Typography } from '@mui/material';

import useDividerTextStyle from './DividerText.style';
import { DividerTextPropsT } from './DividerTextTypes';

const DividerText = ({ text, colorName, className }: DividerTextPropsT): JSX.Element => {
    const { classes } = useDividerTextStyle({ colorName });

    return (
        <div className={`${classes.dividerContainer} ${className}`}>
            <Divider />
            <Typography className={classes.dividerText} component="span" variant="deprecated_body1">
                {text}
            </Typography>
            <Divider />
        </div>
    );
};

export default DividerText;
