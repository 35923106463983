import { ErrorActionsT, ERRORS_ADD, ERRORS_REMOVE, ERRORS_CLEAN } from '../../actions/ErrorActions/ErrorActionsTypes';
import { GetResetT, RESET } from '../../reducers/RootTypes';
import { v4 as uuidv4 } from 'uuid';
import { Reducer } from '@reduxjs/toolkit';

import { ErrorsReducerStateT } from './ErrorsReducerTypes';

export const defaultErrorsState: ErrorsReducerStateT = [];

const ErrorsReducer: Reducer<ErrorsReducerStateT, ErrorActionsT | GetResetT> = (state, action): ErrorsReducerStateT => {
    if (!state) {
        return defaultErrorsState;
    }

    switch (action.type) {
        case ERRORS_ADD: {
            const payload = { ...action.payload };
            const updatedErrors = [...state];
            const errorIdIndex = state.findIndex((err) => err.errorId === action.payload.errorId);

            if (!payload.errorId) {
                payload.errorId = uuidv4();
            } else if (errorIdIndex >= 0) {
                updatedErrors.splice(errorIdIndex, 1);
            }
            updatedErrors.push(payload);

            return updatedErrors;
        }

        case ERRORS_REMOVE: {
            const updatedErrors = [...state];
            const errorIdIndex = state.findIndex((err) => err.errorId === action.payload.errorId);

            if (errorIdIndex >= 0) {
                updatedErrors.splice(errorIdIndex, 1);
            }

            return updatedErrors;
        }

        case ERRORS_CLEAN:
        case RESET: {
            return defaultErrorsState;
        }

        default: {
            return state;
        }
    }
};

export default ErrorsReducer;
