/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTheme } from '@mui/material';
import Button from './components/Button';
import Select from './components/Select';
import { deepmerge } from '@mui/utils';

import breakpoints from './breakpoints';
import mixins from './mixins';
import shadows from './shadows';
import shape from './shape';
import transitions from './transitions';
import zIndex from './zIndex';
import MuiAppBar from './components/AppBar';
import MuiAutocomplete from './components/Autocomplete';
import MuiAvatar from './components/Avatar';
import MuiButton from './components/Button';
import MuiCheckbox from './components/Checkbox';
import MuiFormControl from './components/FormControl';
import MuiFormControlLabel from './components/FormControlLabel';
import MuiInputBase from './components/InputBase';
import MuiSelect from './components/Select';
import MuiSlider from './components/Slider';
import MuiTab from './components/Tab';
import MuiTabs from './components/Tabs';
import MuiLink from './components/Link';
import MuiTextField from './components/TextField';
import MuiTooltip from './components/Tooltip';
import MuiLinearProgress from './components/LinearProgress';
import MuiInputLabel from './components/InputLabel';
import styles from './styles';
import palette from './palette';
import { SCTheme } from '@soil-capital/ui-kit';

import MuiTypography from './components/Typography';

export const themeOptions: any = {
    breakpoints,
    direction: 'ltr',
    mixins,
    components: {
        MuiAppBar,
        MuiAutocomplete,
        MuiAvatar,
        MuiButton,
        MuiCheckbox,
        MuiFormControl,
        MuiFormControlLabel,
        MuiInputBase,
        MuiSelect,
        MuiSlider,
        MuiTab,
        MuiTabs,
        MuiTextField,
        MuiTooltip,
        MuiLinearProgress,
        MuiInputLabel,
        MuiLink,
        Typography: MuiTypography,
    },
    palette,
    shadows,
    typography: SCTheme.typography,
    shape,
    transitions,
    zIndex,
    spacing: 5,
    /* ----------------------------- Custom Options ----------------------------- */
    styles,
    // customShadows: SCTheme.customShadows,
};

const theme = createTheme(
    deepmerge(themeOptions, {
        components: { MuiSelect: Select, MuiButton: Button },
        palette: { ...palette, white: { main: '#ffffff' } },
    }) as any,
);

export default theme;
