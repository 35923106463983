import { FarmSeasonFieldCropDataT } from '../../reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_IRRIGATION_LOADING = 'POST_FARM_SEASON_FIELDS_IRRIGATION_LOADING';
export const POST_FARM_SEASON_FIELDS_IRRIGATION_FAILED = 'POST_FARM_SEASON_FIELDS_IRRIGATION_FAILED';
export const POST_FARM_SEASON_FIELDS_IRRIGATION_SUCCESS = 'POST_FARM_SEASON_FIELDS_IRRIGATION_SUCCESS';

export type FarmSeasonFieldsIrrigationT = {
    id?: number;
    name: string;
    area: number;
    field_crops?: FarmSeasonFieldCropDataT[];
    polygon: any;
    is_irrigated?: boolean;
};

export type FieldIrrigationDataT = {
    id?: number;
    modification?: number;
    irrigation_method_id: number;
    irrigation_water_source_id: number;
    irrigation_power_source_id: number;
    pumping_depth: string;
    horizontal_distance: string;
    total_amount: string;
    percentage: string;
    validation?: number;
};

export type PostFarmSeasonFieldsIrrigationDataT = {
    fields: FarmSeasonFieldsIrrigationT[];
    irrigation: boolean;
};

export type PostFarmSeasonFieldsIrrigationResponseDataT = {
    fields: FarmSeasonFieldsIrrigationT[];
    installation: {
        onboarding: {
            finished: string[];
            irrigation: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsIrrigationPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsIrrigationDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsIrrigationResponseErrorT>;

export type PostFarmSeasonFieldsIrrigationResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsIrrigationLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_IRRIGATION_LOADING;
};

export type PostFarmSeasonFieldsIrrigationFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_IRRIGATION_FAILED;
    error: PostFarmSeasonFieldsIrrigationResponseErrorT;
};

export type PostFarmSeasonFieldsIrrigationSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_IRRIGATION_SUCCESS;
    payload: PostFarmSeasonFieldsIrrigationResponseDataT;
};

export type PostFarmSeasonFieldsIrrigationDispatchTypesT =
    | PostFarmSeasonFieldsIrrigationLoadingT
    | PostFarmSeasonFieldsIrrigationFailedT
    | PostFarmSeasonFieldsIrrigationSuccessT;
