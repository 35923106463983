import { FarmSeasonT } from '../../reducers/FarmSeasonReducer/FarmSeasonReducerTypes';

export const FARM_SEASON_INVALIDATE_BY_AGRONOMIST_FAILED = 'FARM_SEASON_INVALIDATE_BY_AGRONOMIST_FAILED';
export const FARM_SEASON_INVALIDATE_BY_AGRONOMIST_LOADING = 'FARM_SEASON_INVALIDATE_BY_AGRONOMIST_LOADING';
export const FARM_SEASON_INVALIDATE_BY_AGRONOMIST_SUCCESS = 'FARM_SEASON_INVALIDATE_BY_AGRONOMIST_SUCCESS';

export type FarmSeasonInvalidateByAgronomistFailedDataT = {
    message?: string;
};

export type FarmSeasonInvalidateByAgronomistLoadingT = {
    type: typeof FARM_SEASON_INVALIDATE_BY_AGRONOMIST_LOADING;
};
export type FarmSeasonInvalidateByAgronomistSuccessT = {
    type: typeof FARM_SEASON_INVALIDATE_BY_AGRONOMIST_SUCCESS;
    payload: FarmSeasonT;
};
export type FarmSeasonInvalidateByAgronomistFailedT = {
    type: typeof FARM_SEASON_INVALIDATE_BY_AGRONOMIST_FAILED;
    error: FarmSeasonInvalidateByAgronomistFailedDataT;
};

export type FarmSeasonInvalidateByAgronomistDispatchTypesT =
    | FarmSeasonInvalidateByAgronomistLoadingT
    | FarmSeasonInvalidateByAgronomistSuccessT
    | FarmSeasonInvalidateByAgronomistFailedT;
