import { FarmSeasonFieldPreviousHarvestsT } from '../../reducers/FarmSeasonFieldPreviousHarvestReducer/FarmSeasonFieldPreviousHarvestReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { AppActionsT } from '../AppActions/AppActionsTypes';

export const GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_LOADING = 'GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_LOADING';
export const GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_FAILED = 'GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_FAILED';
export const GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_SUCCESS = 'GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_SUCCESS';

export type GetFarmSeasonFieldsPreviousHarvestResponseDataT = {
    history: FarmSeasonFieldPreviousHarvestsT[];
};

export type FarmSeasonFieldsPreviousHarvestGetCallBackT = (
    dispatch: Dispatch<AppActionsT>,
) => Promise<GetFarmSeasonFieldsPreviousHarvestResponseErrorT>;

export type GetFarmSeasonFieldsPreviousHarvestResponseErrorT = {
    message?: string;
    list?: string[];
};

export type GetFarmSeasonFieldsPreviousHarvestLoadingT = {
    type: typeof GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_LOADING;
};

export type GetFarmSeasonFieldsPreviousHarvestFailedT = {
    type: typeof GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_FAILED;
    error: GetFarmSeasonFieldsPreviousHarvestResponseErrorT;
};

export type GetFarmSeasonFieldsPreviousHarvestSuccessT = {
    type: typeof GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_SUCCESS;
    payload: GetFarmSeasonFieldsPreviousHarvestResponseDataT;
};

export type GetFarmSeasonFieldsPreviousHarvestDispatchTypesT =
    | GetFarmSeasonFieldsPreviousHarvestLoadingT
    | GetFarmSeasonFieldsPreviousHarvestFailedT
    | GetFarmSeasonFieldsPreviousHarvestSuccessT;
