import { FarmSeasonFieldT } from '../../reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { PostFarmSeasonOperationSowingApiT } from '../../../services/farmSeasonOperationApiService/farmSeasonOperationApiServiceTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_OPERATION_SOWING_LOADING = 'POST_FARM_SEASON_OPERATION_SOWING_LOADING';
export const POST_FARM_SEASON_OPERATION_SOWING_FAILED = 'POST_FARM_SEASON_OPERATION_SOWING_FAILED';
export const POST_FARM_SEASON_OPERATION_SOWING_SUCCESS = 'POST_FARM_SEASON_OPERATION_SOWING_SUCCESS';

export type PostFarmSeasonOperationSowingDataT = PostFarmSeasonOperationSowingApiT;

export type PostFarmSeasonOperationSowingResponseDataT = {
    fields: FarmSeasonFieldT[];
};

export type FarmSeasonOperationSowingPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonOperationSowingDispatchTypesT>,
) => Promise<PostFarmSeasonOperationSowingResponseErrorT>;

export type PostFarmSeasonOperationSowingResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonOperationSowingLoadingT = {
    type: typeof POST_FARM_SEASON_OPERATION_SOWING_LOADING;
};

export type PostFarmSeasonOperationSowingFailedT = {
    type: typeof POST_FARM_SEASON_OPERATION_SOWING_FAILED;
    error: PostFarmSeasonOperationSowingResponseErrorT;
};

export type PostFarmSeasonOperationSowingSuccessT = {
    type: typeof POST_FARM_SEASON_OPERATION_SOWING_SUCCESS;
    payload: PostFarmSeasonOperationSowingResponseDataT;
};

export type PostFarmSeasonOperationSowingDispatchTypesT =
    | PostFarmSeasonOperationSowingLoadingT
    | PostFarmSeasonOperationSowingFailedT
    | PostFarmSeasonOperationSowingSuccessT;
