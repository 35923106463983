import { Tooltip, Typography } from '@mui/material';
import { createTheme } from '@mui/material';
import { useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ThemeProvider } from '@mui/material';

import useValidationFieldStyle from './ValidationField.style';
import { ValidationFieldPropsT } from './ValidationFieldTypes';

const ValidationField = ({ children, rules, open }: ValidationFieldPropsT) => {
    const { classes } = useValidationFieldStyle();
    const theme = useTheme();

    const newTheme = createTheme({
        ...theme,
        components: {
            ...theme.components,
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        padding: theme.spacing(2),
                        borderRadius: '10px',
                        top: '5px',
                        backgroundColor: '#fff',
                        color: theme.palette.primary.light,
                        boxShadow: '0px 8px 10px 1px rgb(0 0 0 / 5%), 0px 3px 14px 2px rgb(0 0 0 / 15%)',
                    },
                    arrow: {
                        color: '#fff',
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={newTheme}>
            <Tooltip
                open={open}
                placement="right"
                title={
                    <>
                        {rules.map((rule, i) => (
                            <div key={i} className={classes.ruleRow}>
                                {rule.isValid ? (
                                    <CheckCircleIcon className={classes.successColor} fontSize="inherit" />
                                ) : (
                                    <ErrorIcon color="action" fontSize="inherit" />
                                )}
                                <Typography color="secondary" variant="deprecated_body1">
                                    {rule.text}
                                </Typography>
                            </div>
                        ))}
                    </>
                }
                arrow
            >
                <div>{children}</div>
            </Tooltip>
        </ThemeProvider>
    );
};

export default ValidationField;
