import { Components, Theme, ComponentsOverrides } from '@mui/material';

const sliderCss: ComponentsOverrides<Theme>['MuiSlider'] = {
    root: ({ theme }) => ({
        width: '90%',
        '&.Mui-disabled': {
            opacity: 0.6,
            color: theme.palette.primary.light,
            '& .MuiSlider-thumb': {
                width: '14px',
                height: '14px',
            },
        },
    }),
    rail: ({ theme }) => ({
        height: '10px',
        borderRadius: '10px',
        backgroundColor: 'white',
        opacity: 1,
        border: `1px solid ${theme.palette.secondary.main}`,
        boxSizing: 'border-box',
    }),
    valueLabel: ({ theme }) => ({
        left: '-13px',
        top: 0,
        background: 'transparent',
        '& *': {
            color: theme.palette.primary.main,
        },
    }),
    mark: { visibility: 'hidden' },
    markLabel: ({ theme }) => ({
        marginLeft: '5px',
        top: '-10px',
        color: theme.palette.secondary.main,
    }),
    marked: {
        marginBottom: 0,
    },
    track: ({ theme }) => ({
        height: '10px',
        borderRadius: '10px',
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
    }),
    thumb: {
        width: '14px',
        height: '14px',
    },
};

const MuiSlider: Components<Theme>['MuiSlider'] = { styleOverrides: sliderCss };

export default MuiSlider;
