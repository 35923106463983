import { LegacyThemeProvider } from '@shared/deprecated';
import { Navigate, useRoutes } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage/ResetPasswordPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage/ForgotPasswordPage';
import { SignUpPage } from './pages/SignUpPage/SignUpPage';
import { AuthenticatedRoutesGuard } from './components/AuthenticatedRoutesGuard/AuthenticatedRoutes.guard';
import { routesConfig } from './auth.routes';
import { useUpdateIntercom } from '@shared/hooks/useUpdateIntercom';
import { useUpdateHotjar } from '@shared/hooks/useUpdateHotjar';
import { useUpdateUpscope } from '@shared/hooks/useUpdateUpscope';

export const AuthRoot = ({ children }: { children: React.ReactElement }) => {
    useUpdateIntercom();

    useUpdateHotjar();

    useUpdateUpscope();

    const authRoutes = useRoutes([
        {
            path: routesConfig.login,
            element: <LoginPage />,
        },
        {
            path: routesConfig.signup,
            element: <SignUpPage />,
        },
        {
            path: routesConfig.resetPassword,
            element: <ResetPasswordPage />,
        },
        {
            path: routesConfig.forgotPassword,
            element: <ForgotPasswordPage />,
        },
        {
            // we consider that any other path is in the authenticated app side
            path: '*',
            element: <Navigate to={routesConfig.login} replace />,
        },
    ]);
    return (
        <AuthenticatedRoutesGuard
            authenticatedComponent={children}
            unauthenticatedComponent={<LegacyThemeProvider>{authRoutes}</LegacyThemeProvider>}
        />
    );
};
