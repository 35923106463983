import { Button, CircularProgress } from '@mui/material';
import TooltipSc from '../TooltipSc/TooltipSc';

import { LoadingButtonPropsT } from './LoadingButton.types';
import useLoadingButtonStyle from './LoadingButton.style';

const LoadingButton = ({
    className,
    text,
    loading,
    color,
    type,
    variant,
    fullWidth,
    disabled,
    padding,
    lineHeight,
    fontSize,
    onClick,
    disableElevation,
    tooltipSlug,
    sx,
    startIcon,
    id,
}: LoadingButtonPropsT): JSX.Element => {
    const { classes } = useLoadingButtonStyle();

    const button = (
        <Button
            id={id}
            sx={sx}
            className={`${classes.button} ${className}`}
            color={color}
            disableElevation={disableElevation}
            disabled={loading || disabled}
            fullWidth={fullWidth}
            style={{ padding, lineHeight, fontSize }}
            type={type}
            variant={variant}
            onClick={onClick}
            startIcon={startIcon}
        >
            {text}
            {loading && (
                <div className={classes.loading}>
                    <CircularProgress className={classes.progress} size="24px" />
                </div>
            )}
        </Button>
    );

    return tooltipSlug ? (
        <TooltipSc placement="bottom" translationSlug={tooltipSlug || ''}>
            {button}
        </TooltipSc>
    ) : (
        button
    );
};

export default LoadingButton;
