import { makeStyles } from 'tss-react/mui';

export const useResultsEarningStyle = makeStyles()((theme) => ({
    resultsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(3),
    },
    graphContainer: {
        padding: theme.spacing(2),
        minWidth: '45%',
        flexGrow: 1,
    },
    earningContainer: {
        padding: theme.spacing(3),
        background: theme.palette.darkScale[50],
        borderRadius: theme.shape.borderRadius,
    },
    priceTitle: {
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.darkScale[700],
    },
    priceRange: {
        display: 'block',
        marginTop: theme.spacing(3),
    },
    tonsEquivalent: {
        display: 'block',
        marginBottom: theme.spacing(4),
    },
    carbonFootprint: {
        display: 'block',
        marginTop: theme.spacing(3),
        color: theme.palette.darkScale[700],
    },
    accordionHeader: {
        fontSize: theme.typography.h2.fontSize,
    },
    detailedCalculation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(0.5),
        paddingBottom: theme.spacing(3),
    },
    accordionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
    blurredContainer: {
        position: 'relative',
    },
    blurred: {
        filter: 'blur(8px)',
        opacity: 0.9,
    },
    soonAvailableContainer: {
        left: '50%',
        top: '50%',
        position: 'absolute',
        height: '100%',
        width: '100%',
        transform: 'translate(-50%, -50%)',
        zIndex: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    soonAvailable: {
        position: 'relative',
    },
    arrow: {
        position: 'absolute',
        top: '-110%',
        left: '-15%',
    },
}));
