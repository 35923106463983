import { Checkbox, Input, Select, MenuItem, ConditionalTooltip } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import useBillingFormStyle from './useBillingInfoForm.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { FormProvider } from 'react-hook-form';
import { useBillingInfoFormLogic } from './useBillingInfoFormLogic';
import { BILLING_INFOS } from './useBillingInfoFormLogic';
import { forwardRef, useEffect } from 'react';
import { CompanyT } from '@modules/billing/api/userCompany.types';

export type BillingInfoFormProps = {
    refetch: () => void;
    setOnConfirm: (confirmFn: () => void) => void;
    onClose?: () => void;
    userCompany?: CompanyT;
    setDisableContinue?: (disable: boolean) => void;
    setIsSubmitting?: (isSubmitting: boolean) => void;
    nextAction?: () => void;
};

export const BillingInfoForm = forwardRef(
    (
        {
            setOnConfirm,
            userCompany,
            onClose,
            refetch,
            setDisableContinue,
            setIsSubmitting,
            nextAction,
        }: BillingInfoFormProps,
        ref,
    ) => {
        const { t } = useTranslation();
        const { classes } = useBillingFormStyle();
        const {
            countries,
            methods,
            errors,
            onConfirm,
            handlePrefillClick,
            selectedCountry,
            isFromFrance,
            isReadOnly,
            canEditIban,
        } = useBillingInfoFormLogic(refetch, onClose, userCompany, setDisableContinue, setIsSubmitting, nextAction);

        useEffect(() => {
            setOnConfirm(onConfirm);
        }, [setOnConfirm, onConfirm]);

        return (
            <FormProvider {...methods}>
                <form className={classes.form} data-testid="billing-form">
                    <div className={classes.flexbox}>
                        <Checkbox
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handlePrefillClick(e.target.checked);
                            }}
                            disabled={isReadOnly}
                        />
                        <Typography variant="body2">
                            {t('billing.subscription.billing-form.billing-address.checkbox')}
                        </Typography>
                    </div>
                    <div className={classes.group}>
                        <Input
                            label={t('billing.subscription.billing-form.company-name')}
                            placeholder={t('billing.subscription.billing-form.company-name.placeholder')}
                            inputProps={{
                                ...methods.register(BILLING_INFOS.NAME),
                            }}
                            type="text"
                            data-1p-ignore
                            disabled={isReadOnly}
                        />
                        {errors[BILLING_INFOS.NAME]?.message && (
                            <Typography variant="caption" color="error">
                                {t(`${errors[BILLING_INFOS.NAME]?.message}`)}
                            </Typography>
                        )}
                    </div>
                    <div className={classes.group}>
                        <Input
                            label={t('billing.subscription.billing-form.address')}
                            placeholder={t('billing.subscription.billing-form.address.placeholder')}
                            inputProps={{
                                ...methods.register(BILLING_INFOS.ADDRESS),
                            }}
                            type="text"
                            disabled={isReadOnly}
                        />
                        {errors[BILLING_INFOS.ADDRESS]?.message && (
                            <Typography variant="caption" color="error">
                                {t(`${errors[BILLING_INFOS.ADDRESS]?.message}`)}
                            </Typography>
                        )}
                    </div>
                    <div className={classes.addressGroup}>
                        <div className={classes.group}>
                            <Input
                                label={t('billing.subscription.billing-form.city')}
                                placeholder={t('billing.subscription.billing-form.city.placeholder')}
                                inputProps={{
                                    ...methods.register(BILLING_INFOS.CITY),
                                }}
                                type="text"
                                disabled={isReadOnly}
                            />
                            {errors[BILLING_INFOS.CITY]?.message && (
                                <Typography variant="caption" color="error">
                                    {t(`${errors[BILLING_INFOS.CITY]?.message}`)}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.group}>
                            <Input
                                label={t('billing.subscription.billing-form.postal-code')}
                                placeholder={t('billing.subscription.billing-form.postal-code.placeholder')}
                                inputProps={{
                                    ...methods.register(BILLING_INFOS.POSTAL_CODE),
                                }}
                                type="text"
                                disabled={isReadOnly}
                            />
                            {errors[BILLING_INFOS.POSTAL_CODE]?.message && (
                                <Typography variant="caption" color="error">
                                    {t(`${errors[BILLING_INFOS.POSTAL_CODE]?.message}`)}
                                </Typography>
                            )}
                        </div>
                        <Select
                            {...methods.register(BILLING_INFOS.COUNTRY)}
                            ref={ref}
                            label={t('billing.subscription.billing-form.country').toUpperCase()}
                            placeholder={`${t('constants.select')}...`}
                            value={selectedCountry}
                            fullWidth
                            displayEmpty
                            disabled={isReadOnly}
                        >
                            <MenuItem sx={{ display: 'none' }} value={0} disabled>
                                {`${t('constants.select')}...`}
                            </MenuItem>
                            {countries.length > 0 &&
                                countries.map((country) => (
                                    <MenuItem key={country.id} value={country.id.toString()}>
                                        {t(country.translation_slug)}
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>
                    <div className={classes.group}>
                        <div className={classes.flexbox}>
                            <Typography variant="caption">
                                {t('billing.subscription.billing-form.VAT').toUpperCase()}
                            </Typography>
                            <ConditionalTooltip text={t('billing.subscription.billing-form.VAT.tooltip')} />
                        </div>
                        <Input
                            label=""
                            placeholder={t('billing.subscription.billing-form.VAT.placeholder')}
                            inputProps={{
                                ...methods.register(BILLING_INFOS.VAT),
                            }}
                            type="text"
                            disabled={isReadOnly}
                        />
                        {errors[BILLING_INFOS.VAT]?.message && (
                            <Typography variant="caption" color="error">
                                {t(`${errors[BILLING_INFOS.VAT]?.message}`)}
                            </Typography>
                        )}
                    </div>
                    {isFromFrance && (
                        <div className={classes.group}>
                            <Input
                                data-testid="siret-input"
                                label={t('billing.subscription.billing-form.siret')}
                                placeholder={t('billing.subscription.billing-form.siret.placeholder')}
                                inputProps={{
                                    ...methods.register(BILLING_INFOS.SIRET),
                                }}
                                type="text"
                                disabled={isReadOnly}
                            />
                            {errors[BILLING_INFOS.SIRET]?.message && (
                                <Typography variant="caption" color="error">
                                    {t(`${errors[BILLING_INFOS.SIRET]?.message}`)}
                                </Typography>
                            )}
                        </div>
                    )}
                    <div className={classes.group}>
                        <div className={classes.flexbox}>
                            <Typography variant="caption">
                                {t('billing.subscription.billing-form.IBAN').toUpperCase()}
                            </Typography>
                            <ConditionalTooltip text={t('billing.subscription.billing-form.IBAN-tooltip')} />
                        </div>
                        <Input
                            label=""
                            placeholder={t('billing.subscription.billing-form.IBAN.placeholder')}
                            inputProps={{
                                ...methods.register(BILLING_INFOS.IBAN),
                            }}
                            type="text"
                            disabled={isReadOnly || !canEditIban}
                        />
                        {errors[BILLING_INFOS.IBAN]?.message && (
                            <Typography variant="caption" color="error">
                                {t(`${errors[BILLING_INFOS.IBAN]?.message}`)}
                            </Typography>
                        )}
                        <Typography variant="subcaption" className={classes.subcaption}>
                            {t('billing.subscription.billing-form.IBAN-description')}
                        </Typography>
                    </div>
                </form>
            </FormProvider>
        );
    },
);
