import { FertiliserT } from '../../reducers/FertiliserReducer/FertiliserReducerTypes';

export const FERTILISERS_GET_LOADING = 'FERTILISERS_GET_LOADING';
export const FERTILISERS_GET_SUCCESS = 'FERTILISERS_GET_SUCCESS';
export const FERTILISERS_GET_FAILED = 'FERTILISERS_GET_FAILED';

export type FertilisersGetSuccessDataT = FertiliserT[];

export type FertilisersGetFailedDataT = {
    message?: string;
    list?: string[];
};

export type FertilisersGetLoadingT = {
    type: typeof FERTILISERS_GET_LOADING;
};

export type FertilisersGetSuccesT = {
    type: typeof FERTILISERS_GET_SUCCESS;
    payload: FertilisersGetSuccessDataT;
};

export type FertilisersGetFailedT = {
    type: typeof FERTILISERS_GET_FAILED;
    error: FertilisersGetFailedDataT;
};

export type FertilisersGetDispatchTypes = FertilisersGetLoadingT | FertilisersGetSuccesT | FertilisersGetFailedT;
