import { makeStyles } from 'tss-react/mui';

const useLabelNumberFieldStyle = makeStyles<{ borderColor?: 'grey' }>()((theme, { borderColor }) => ({
    textField: {
        '& .MuiInputBase-root fieldset': {
            borderColor: borderColor === 'grey' ? theme.palette.grey['300'] : undefined,
        },
        '& .MuiInputAdornment-root p': {
            color: theme.palette.deprecated,
            fontSize: '13px',
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    label: {
        marginBottom: '10px',
    },
    textFieldContainer: {
        position: 'relative',
    },
    topIcon: {
        height: '16px',
        position: 'absolute',
        top: '5px',
        right: '10%',
        cursor: 'pointer',
        fontSize: '1.1rem',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
    bottomIcon: {
        height: '16px',
        position: 'absolute',
        top: '22px',
        right: '10%',
        fontSize: '1.1rem',
        cursor: 'pointer',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
}));

export default useLabelNumberFieldStyle;
