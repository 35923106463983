import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ResetPasswordPageView from './ResetPasswordPageView';
import { ResetPasswordDataT, ResetPasswordRouteParamsT } from './ResetPasswordPageTypes';
import authApi from '@modules/auth/api/authApi';

export const ResetPasswordPage = (): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [resetPassword, { isLoading: resetPasswordLoading }] = authApi.useResetPasswordMutation();
    const [msgOpen, setMsgOpen] = useState(false);
    const { resetCode } = useParams<ResetPasswordRouteParamsT>();

    const handleMsgOpen = (): void => {
        setMsgOpen(true);
    };

    useEffect(() => {
        if (msgOpen) {
            setTimeout(() => {
                navigate('/login');
            }, 8000);
        }
    }, [msgOpen, navigate]);

    const validationSchema = yup.object({
        email: yup.string().email(t('validation.invalid-email')).required(t('validation.no-email-provided')),
        password: yup
            .string()
            .required(t('validation.no-password-provided'))
            .min(8, t('validation.password-must-be-at-least-x-characters', { min: 8 }))
            .matches(/^(?=.*[0-9])/, t('validation.password-must-at-least-contain-one-figure'))
            .matches(
                /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/,
                t('validation.password-must-at-least-contain-one-special-character'),
            ),
        password_confirmation: yup
            .string()
            .oneOf([yup.ref('password')], t('validation.password-must-match'))
            .required(t('validation.no-password-confirmation')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            password_confirmation: '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: ResetPasswordDataT, { setSubmitting, setErrors, resetForm }) => {
            if (!resetCode) {
                throw Error('resetCode is required to call "resetPassword"');
            }

            resetPassword({ ...values, token: resetCode })
                .unwrap()
                .then(() => {
                    resetForm();
                    handleMsgOpen();
                })
                .catch((error) => {
                    const error_code = error.data.code?.replace('validation.', '');
                    const error_string = error_code ? t(`validation.${error_code}`) : error.data.error;

                    setErrors({
                        email: error_string,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    return <ResetPasswordPageView formik={formik} loading={resetPasswordLoading} msgOpen={msgOpen} t={t} />;
};
