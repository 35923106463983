import { AuthRoot } from '@modules/auth/Auth.root';
import encoding from '@modules/encoding';
import results from '@modules/results';
import billing from '@modules/billing';
import payment from '@modules/payment';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { UpscopeProvider } from '@upscopeio/react';
import { ThemeProvider, CssBaseline, StyledEngineProvider } from '@soil-capital/ui-kit/material-core';
import { SCTheme } from '@soil-capital/ui-kit/theme';
import { SharedStoreProvider } from '@shared/store';
import GuardApp from './GuardApp';
import { NotFoundRoute } from '@shared/errors';
import React from 'react';
import SnackbarProvider from './components/Snackbar/Snackbar.provider';
import CustomErrorBoundary from './components/CustomErrorBoundary/CustomErrorBoundary';
import { HomePage } from './pages/HomePage/HomePage';

export default () => {
    return (
        <React.StrictMode>
            <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_KEY ?? ''} autoBoot>
                <UpscopeProvider apiKey={process.env.REACT_APP_UPSCOPE_API_KEY ?? ''} enabled={true}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={SCTheme}>
                            <CssBaseline />
                            <SharedStoreProvider>
                                <CustomErrorBoundary>
                                    <SnackbarProvider>
                                        <GuardApp>
                                            <BrowserRouter>
                                                <Routes>
                                                    {NotFoundRoute}
                                                    <Route
                                                        path="*"
                                                        element={
                                                            <AuthRoot>
                                                                <Routes>
                                                                    <Route path="/:seasonId/" element={<HomePage />} />
                                                                    <Route
                                                                        path="/:seasonId/results/*"
                                                                        element={<results.Component />}
                                                                    />
                                                                    <Route
                                                                        path="/:seasonId/billing/*"
                                                                        element={<billing.Component />}
                                                                    />
                                                                    <Route
                                                                        path="/:seasonId/payment/*"
                                                                        element={<payment.Component />}
                                                                    />
                                                                    <Route
                                                                        path="/:seasonId/*"
                                                                        element={<encoding.Component />}
                                                                    />
                                                                    <Route path="*" element={<encoding.Component />} />
                                                                </Routes>
                                                            </AuthRoot>
                                                        }
                                                    />
                                                </Routes>
                                            </BrowserRouter>
                                        </GuardApp>
                                    </SnackbarProvider>
                                </CustomErrorBoundary>
                            </SharedStoreProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </UpscopeProvider>
            </IntercomProvider>
        </React.StrictMode>
    );
};
