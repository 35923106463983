import { UserT } from '../../reducers/AuthReducer/AuthReducerTypes';

export const GET_VALIDATE_COOKIE_LOADING = 'GET_VALIDATE_COOKIE_LOADING';
export const GET_VALIDATE_COOKIE_FAILED = 'GET_VALIDATE_COOKIE_FAILED';
export const GET_VALIDATE_COOKIE_SUCCESS = 'GET_VALIDATE_COOKIE_SUCCESS';

export type GetValidateCookieResponseDataT = {
    user: UserT;
    access_token: string;
    expires_in: number;
};

export type GetValidatedAuthResponseErrorT = {
    message?: string;
    list?: string[];
};

export type GetValidateCookieLoadingT = {
    type: typeof GET_VALIDATE_COOKIE_LOADING;
};

export type GetValidateCookiedFailedT = {
    type: typeof GET_VALIDATE_COOKIE_FAILED;
    error: GetValidatedAuthResponseErrorT;
};

export type GetValidateCookieSuccessT = {
    type: typeof GET_VALIDATE_COOKIE_SUCCESS;
    payload: GetValidateCookieResponseDataT;
};

export type GetValidateCookieDispathTypesT =
    | GetValidateCookieLoadingT
    | GetValidateCookiedFailedT
    | GetValidateCookieSuccessT;
