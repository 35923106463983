import { Box, Link, Button } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    LoadingButton,
    LabelTextField,
    LayoutAuth,
    DividerText,
    LabelPasswordField,
    ErrorMessageView,
} from '@shared/deprecated';
import useLoginPageStyle from './LoginPage.style';

import { LoginPageViewPropsI } from './LoginPageTypes';

const LoginPageView = ({
    loading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    auth0Loading,
    t,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    remember,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setRemember,
    userError,
    formik,
}: LoginPageViewPropsI): JSX.Element => {
    const { classes } = useLoginPageStyle();
    const navigate = useNavigate();

    const canRegister = process.env.REGISTER_ENABLED === 'true';

    return (
        <LayoutAuth
            form={
                <form id="login-form" onSubmit={formik.handleSubmit}>
                    <LabelTextField
                        autoComplete="email"
                        disabled={loading}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        id="email"
                        label={t('pages.login.email-address')}
                        name="email"
                        type="email"
                        value={formik.values.email}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={formik.handleChange}
                    />
                    <LabelPasswordField
                        autoComplete="current-password"
                        disabled={loading}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        id="password"
                        label={t('pages.login.password')}
                        name="password"
                        value={formik.values.password}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={formik.handleChange}
                    />
                    <Box mb={2} mt={-1} textAlign="left">
                        <Link className={classes.forgotLink} component={RouterLink} to="../forgot-password">
                            {t('pages.login.forgot-password')}
                        </Link>
                    </Box>
                    {/* <Box className={classes.checkbox} mb={2} textAlign="left">
                        <LabelCheckboxView
                            checked={remember}
                            label={t('pages.login.remember-me')}
                            name="remember-me"
                            onChange={(e, checked) => setRemember(checked)}
                        />
                    </Box> */}
                    <ErrorMessageView message={userError} />
                    <Box my={4}>
                        <LoadingButton
                            loading={loading}
                            text={t('constants.login')}
                            type="submit"
                            variant="contained"
                            disableElevation
                            fullWidth
                        />
                    </Box>
                    <DividerText className={classes.divider} text={t('pages.login.create-account-question')} />
                    <Button
                        className={classes.buttonLight}
                        style={{ width: '100%' }}
                        onClick={() => navigate('../signup')}
                        disabled={!canRegister}
                    >
                        {t('constants.signup')}
                    </Button>
                    {/* <DividerText className={classes.divider} text={t('pages.signup.auth0-divider')} /> */}
                    {/* <Auth0Buttons auth0Loading={auth0Loading} /> */}
                </form>
            }
            sideZone={<div className={classes.sideZoneImage}></div>}
            title={t('pages.login.log-in-title')}
        />
    );
};

export default LoginPageView;
