import { Components, Theme } from '@mui/material';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

const InputLabelCss: ComponentsOverrides<Theme>['MuiInputLabel'] = {
    root: ({ theme }) => ({
        ...theme.typography.deprecated_caption,
    }),
};

const MuiInputLabel: Components<Theme>['MuiInputLabel'] = { styleOverrides: InputLabelCss };

export default MuiInputLabel;
