import { TreeT } from '../../reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const TREES_GET_LOADING = 'TREES_GET_LOADING';
export const TREES_GET_SUCCESS = 'TREES_GET_SUCCESS';
export const TREES_GET_FAILED = 'TREES_GET_FAILED';

export type TreesGetSuccessDataT = TreeT[];
export type TreesGetFailedDataT = {
    message?: string;
    list?: string[];
};

export type TreesGetCallBackT = (dispatch: Dispatch<TreesDispatchTypes>) => Promise<TreesGetFailedDataT>;
export type TreesGetLoadingT = {
    type: typeof TREES_GET_LOADING;
};

export type TreesGetSuccessT = {
    type: typeof TREES_GET_SUCCESS;
    payload: TreesGetSuccessDataT;
};

export type TreesGetFailedT = {
    type: typeof TREES_GET_FAILED;
    error: TreesGetFailedDataT;
};

export type TreesDispatchTypes = TreesGetLoadingT | TreesGetSuccessT | TreesGetFailedT;
