import { useSelector } from 'react-redux';
import { getHasExistingToken } from '../utils/getHasAccessTokenCookie';
import slice, { AuthStateT } from './authSlice';

const useAuthSelector = <S>(cb: (s: AuthStateT) => S): S =>
    useSelector((state: { [slice.name]: AuthStateT }) => cb(state[slice.name]));

const useAuthUser = () => useAuthSelector((s) => s.authUser);
const useIsAuthenticated = () => useAuthSelector((s) => !!s.authUser) && getHasExistingToken().hasAccessTokenCookie;
const useUrlToRedirect = () => useAuthSelector((s) => s.urlToRedirect);
const useShouldRedirectToV2 = () => {
    // if the user has already a season + is cft model, we deduct it's a cohort 1-2-3 or argentina-1
    // if the user has 'cft' model type but no latestActiveSeason, we deduct it's a argentina-1 still in the subscription flow
    //   (only argentiona is registering with cft model) -> we don't redirect
    // if the user has 'dndc' model type -> we don't redirect.
    const isV2Farmer = useAuthSelector(
        (s) => s.farmerInfos?.modelType === 'cft' && !!s.farmerInfos.latestActiveSeasonId,
    );
    return isV2Farmer;
};
const useLatestActiveSeasonId = () => useAuthSelector((s) => s.farmerInfos?.latestActiveSeasonId);

export default {
    useIsAuthenticated,
    useAuthUser,
    useUrlToRedirect,
    useShouldRedirectToV2,
    useLatestActiveSeasonId,
};
