import { makeStyles } from 'tss-react/mui';
import { HelpButtonViewStyleProps } from './HelpButtonViewTypes';

const useHelpButtonViewStyle = makeStyles<HelpButtonViewStyleProps>()((theme, { height, size }) => ({
    container: {
        padding: 0,
        height: height || '12px',
        width: size?.innerBody,
        marginLeft: '5px',
        display: 'inline-block',
        position: 'relative',
        '& > a': {
            position: 'absolute',
            background: '#FFF',
            height: size?.innerBody,
            width: size?.innerBody,
            borderRadius: '100%',
        },
    },
    help: {
        position: 'absolute',
        top: size?.position,
        left: size?.position,
        height: size?.outerBody,
        width: size?.outerBody,
        padding: '0px',
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
}));

export default useHelpButtonViewStyle;
