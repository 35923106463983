import { SeasonT } from '../../reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const SEASON_GET_LOADING = 'SEASON_GET_LOADING';
export const SEASON_GET_SUCCESS = 'SEASON_GET_SUCCESS';
export const SEASON_GET_FAILED = 'SEASON_GET_FAILED';

export type SeasonGetSuccessDataT = SeasonT[];
export type SeasonGetFailedDataT = {
    message?: string;
    list?: string[];
};

export type SeasonGetCallBackT = (dispatch: Dispatch<SeasonDispatchTypes>) => Promise<SeasonGetFailedDataT>;
export type SeasonGetLoadingT = {
    type: typeof SEASON_GET_LOADING;
};

export type SeasonGetSuccessT = {
    type: typeof SEASON_GET_SUCCESS;
    payload: SeasonGetSuccessDataT;
};

export type SeasonGetFailedT = {
    type: typeof SEASON_GET_FAILED;
    error: SeasonGetFailedDataT;
};

export type SeasonDispatchTypes = SeasonGetLoadingT | SeasonGetSuccessT | SeasonGetFailedT;
