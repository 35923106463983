import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useForgotPasswordPageStyle = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        minHeight: '600px',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 'none',
        padding: 0,
        backgroundColor: alpha(theme.palette.secondary.light, 0.15),
    },
    box: {
        border: `1px solid ${theme.palette.grey['300']}`,
        backgroundColor: 'white',
        maxWidth: '300px',
        boxSizing: 'content-box',
    },
    logoImg: {
        maxWidth: '140px',
        marginBottom: '40px',
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '1.5em',
        marginBottom: '30px',
    },
    loginLink: {
        textDecoration: 'underline',
    },
    wrapper: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    circle: {
        backgroundColor: theme.palette.primary.main,
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        boxShadow: theme.shadows[8],
        cursor: 'default',
        marginBottom: '30px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    iconCheck: {
        width: '58px',
        height: '58px',
        fill: theme.palette.common.white,
    },
    msg: {
        fontSize: '1rem',
        color: theme.palette.primary.light,
        fontFamily: theme.typography.deprecated_body1.fontFamily,
        textAlign: 'center',
    },
}));

export default useForgotPasswordPageStyle;
