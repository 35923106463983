import { Components, ComponentsOverrides, Theme } from '@mui/material';

const tooltipCss: ComponentsOverrides<Theme>['MuiTooltip'] = {
    tooltip: ({ theme }) => ({
        backgroundColor: theme.palette.primary.light,
        borderRadius: '8px',
        padding: '12px',
    }),
    arrow: ({ theme }) => ({
        color: theme.palette.primary.light,
    }),
};

const MuiTooltip: Components<Theme>['MuiTooltip'] = { styleOverrides: tooltipCss };

export default MuiTooltip;
