import { PaymentStepTimelinePropsT } from './PaymentStepTimeline.types';
import { usePaymentStepTimelineInvoiceLogic } from './logic/usePaymentStepTimelineInvoiceLogic';
import { usePaymentStepTimelineSlugLogic } from './logic/usePaymentStepTimelineSlugLogic';
import { usePaymentStepTimelineConditionsLogic } from './logic/usePaymentStepTimelineConditionsLogic';
import { auth } from '@modules/auth';

export const usePaymentStepTimelineLogic = ({ payment, status, farmId }: PaymentStepTimelinePropsT) => {
    const isReadOnly = !auth.usePermission('canEditBillingPage');

    const { openPdfInNewTab, isPdfLoading } = usePaymentStepTimelineInvoiceLogic({
        farmSeasonProductPlanId: payment.id,
        invoiceId: payment.invoice_id,
    });

    const { isLoading, redirectToPaymentFlow, paymentContext } = usePaymentStepTimelineConditionsLogic({
        payment,
        status,
        farmId,
    });

    const {
        title,
        displayedPrice,
        priceTooltip,
        displayedPayment,
        paymentTooltip,
        displayCTA,
        CTAlabel,
        displayDescription,
        displayDescriptionIcon,
        descriptionTransKey,
    } = usePaymentStepTimelineSlugLogic({
        payment,
        ...paymentContext,
    });

    return {
        currency: paymentContext.currency,
        title,
        displayedPrice,
        priceTooltip,
        displayedPayment,
        paymentTooltip,
        displayCTA,
        CTAlabel,
        canRenew: paymentContext.canRenew,
        displayDescription,
        displayDescriptionIcon,
        descriptionTransKey,
        discountedPrice: paymentContext.discountedPrice,
        isFuturePayment: paymentContext.isFuturePayment,
        isBaselinePayment: paymentContext.isBaselinePayment,
        redirectToPaymentFlow,
        openPdfInNewTab,
        isPdfLoading,
        invoiceId: payment.invoice_id,
        isReadOnly,
        isFailedPayment: paymentContext.isFailedPayment,
        isPendingPayment: paymentContext.isPendingPayment,
        isLoading,
    };
};
