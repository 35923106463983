import { UserT } from '../../reducers/AuthReducer/AuthReducerTypes';
import { AxiosResponse } from 'axios';

export const SIGNUP_LOADING = 'SIGNUP_LOADING';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export type SignUpActionDataT = {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    referral_code?: string;
};

export type SignUpResponseDataT = {
    user: UserT;
    access_token: string;
    expires_in: number;
};

export type SignUpPayloadT = {
    data: SignUpResponseDataT;
} & AxiosResponse;

export type SignUpResponseErrorT = {
    message?: string;
    list?: string[];
};

export type SignUpLoadingT = {
    type: typeof SIGNUP_LOADING;
};

export type SignUpFailedT = {
    type: typeof SIGNUP_FAILED;
    error: SignUpResponseErrorT;
};

export type SignUpSuccessT = {
    type: typeof SIGNUP_SUCCESS;
    payload: SignUpResponseDataT;
};

export type SignUpDispatchTypesT = SignUpLoadingT | SignUpFailedT | SignUpSuccessT;
