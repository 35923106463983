import { Components, Theme } from '@mui/material';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

const FormControlCss: ComponentsOverrides<Theme>['MuiFormControl'] = {
    root: ({ theme }: { theme: Theme }) => ({
        marginLeft: 0,
        marginRight: 0,
        '& .MuiTypography-body1': {
            ...theme.typography.deprecated_h6,
            fontSize: '0.95rem',
            color: theme.palette.primary.light,
            marginLeft: '2px',
            transform: 'translate(0, 2px)',
        },
    }),
};

const MuiFormControl: Components<Theme>['MuiFormControl'] = { styleOverrides: FormControlCss };

export default MuiFormControl;
