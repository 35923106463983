/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField, Autocomplete as MuiAutocomplete } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { SvgIcon, FilterOptionsState } from '@mui/material';
import IconDown from './icon-down.svg';
import AddIcon from '@mui/icons-material/Add';

import { AutocompletePropsT } from './AutocompleteTypes';
import useAutocompleteStyle from './Autocomplete.style';

const normalizeString = (str: string) =>
    str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

const searchString = (needle: string, haystack: string): boolean =>
    normalizeString(haystack).indexOf(normalizeString(needle)) !== -1;

export type NumberFieldValue = number | '';

const Autocomplete = <T, V extends NumberFieldValue | string>({
    className,
    onChange,
    options,
    value,
    borderColor,
    getOptionDisabled,
    getOptionLabel,
    getPropSelected = (option: any) => option?.id || '',
    loading = false,
    onCreate,
    placeholder,
    sortList = true,
    disabled,
    error,
    helperText,
}: AutocompletePropsT<T, V>): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useAutocompleteStyle({ disabled, borderColor });

    if (!getOptionLabel) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getOptionLabel = (option: any) => t(option?.translation_slug);
    }

    const sortedOptions = options?.length > 0 ? [...options] : [];

    if (sortList) {
        sortedOptions.sort((a, b) => {
            const nameA = getOptionLabel?.(a)?.toUpperCase() ?? '';
            const nameB = getOptionLabel?.(b)?.toUpperCase() ?? '';

            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }

    const createButtonId = 'custom-option-create';

    const filterOptions = (optionsToFilter: T[], state: FilterOptionsState<T>) =>
        optionsToFilter.filter(
            (option) =>
                (option &&
                    (option as any)?.id !== createButtonId &&
                    searchString(state.inputValue, getOptionLabel?.(option) ?? '')) ||
                (onCreate && (option as any)?.id === createButtonId),
        );

    const renderOption = (props: any, option: any) =>
        option?.id === createButtonId ? (
            <div {...props} className={classes.optionCreate}>
                <AddIcon />
                {t((option as any).translation_slug)}
            </div>
        ) : (
            <div {...props}>{getOptionLabel?.(option)}</div>
        );

    return (
        <></>
        // <MuiAutocomplete
        //     disabled={disabled}
        //     className={`${classes.root} ${className}`}
        //     filterOptions={filterOptions}
        //     getOptionDisabled={getOptionDisabled}
        //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //     getOptionLabel={(option: T) => ((option as any)?.id !== createButtonId && getOptionLabel?.(option)) || ''}
        //     loading={loading}
        //     loadingText={t('constants.loading')}
        //     options={[
        //         // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //         { id: createButtonId, translation_slug: 'components.autocomplete.create-button' } as any,
        //         ...sortedOptions,
        //         '',
        //     ]}
        //     popupIcon={
        //         <div className={classes.iconContainerStyle}>
        //             <SvgIcon className={classes.iconStyle} component={IconDown} viewBox="0 0 9 9" />
        //         </div>
        //     }
        //     renderInput={(params) => (
        //         <TextField
        //             {...params}
        //             error={error}
        //             helperText={helperText}
        //             inputProps={{
        //                 ...params.inputProps,
        //             }}
        //             placeholder={placeholder}
        //             variant="outlined"
        //         />
        //     )}
        //     renderOption={renderOption}
        //     value={(sortedOptions.find((option) => getPropSelected(option) === value) as any) || ''}
        //     onChange={(e, newOptionSelected?: T) => {
        //         if ((newOptionSelected as any)?.id === createButtonId) {
        //             onCreate?.();
        //         } else {
        //             onChange(newOptionSelected ? getPropSelected(newOptionSelected) : ('' as V));
        //         }
        //     }}
        // />
    );
};

export default Autocomplete;
