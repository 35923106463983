import { Components, ComponentsOverrides, Theme } from '@mui/material';

const textFieldCss: ComponentsOverrides<Theme>['MuiTextField'] = {
    root: ({ theme }) => ({
        '& .MuiInputBase-root': {
            background: 'rgba(255,255,255,0.4)',
            borderRadius: '10px',
            '&.Mui-focused': {
                background: '#FFF',
            },
            '& fieldset': {
                borderColor: theme.palette.secondary.light,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '& input': {
                padding: '15px 20px',
                color: theme.palette.primary.light,
                height: '13px',
            },
        },
    }),
};

const MuiTextField: Components<Theme>['MuiTextField'] = { styleOverrides: textFieldCss };

export default MuiTextField;
