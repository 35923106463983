import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import LoginPageView from './LoginPageView';
import { useFormik } from 'formik';
import authActions from '@modules/auth/store/authActions';

export const LoginPage = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    // const auth0Loading = useSelector((state: AppStateT) => state.auth.auth0Loading); // TODO implement auth0 authentication
    const [remember, setRemember] = useState(false);

    const { t } = useTranslation();
    const validationSchema = yup.object({
        email: yup.string().email(t('validation.invalid-email')).required(t('validation.no-email-provided')),
        password: yup.string().required(t('validation.no-password-provided')),
    });

    const activeUserError = null; // TODO handle unauthorizes
    // = useSelector((state: AppStateT) =>
    //     state.auth.error?.list?.find((err) => err === 'user-not-active') ? t('errors.user-not-active') : null,
    // );

    const allowedUserError = null;
    // useSelector((state: AppStateT) =>
    //     state.auth.error?.list?.find((err) => err === 'user-not-allowed') ? t('errors.user-not-allowed') : null,
    // );
    const { login, loginLoading } = authActions.useLogin();

    const formik = useFormik({
        initialValues: {
            email: searchParams.get('email') ?? '',
            password: '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async ({ email, password }, { setSubmitting, setErrors }) => {
            if (password.length >= 6) {
                login({ email: email.toLowerCase(), password })
                    .catch((error) => {
                        const error_code =
                            error.data?.code === 'invalid_credentials' ? 'invalid-email-or-password' : error.data?.code;

                        const error_string = error_code ? t(`validation.${error_code}`) : error.data?.error;

                        setErrors({
                            email: error_string,
                            password: error_string,
                        });
                    })
                    .finally(() => setSubmitting(false));
            }
        },
    });

    return (
        <LoginPageView
            userError={activeUserError || allowedUserError}
            // auth0Loading={auth0Loading}
            formik={formik}
            auth0Loading={false}
            loading={loginLoading}
            remember={remember}
            setRemember={setRemember}
            t={t}
        />
    );
};
