import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useErrorMessageViewStyle = makeStyles()((theme) => ({
    container: {
        backgroundColor: alpha(theme.palette.error.light, 0.1),
        border: `1px solid ${alpha(theme.palette.error.main, 0.6)}`,
        marginBottom: '10px',
        padding: '5px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: '24px',
        marginRight: '5px',
        color: theme.palette.error.dark,
    },
    typography: {
        color: theme.palette.error.dark,
    },
}));

export default useErrorMessageViewStyle;
