import { Components, ComponentsOverrides, Theme } from '@mui/material';

const tabCss: ComponentsOverrides<Theme>['MuiTab'] = {
    root: {
        backgroundColor: 'transparent',
    },
    textColorSecondary: ({ theme }) => ({
        color: theme.palette.deprecated_driftwood.light,
        '&.Mui-selected': {
            color: theme.palette.deprecated_driftwood.dark,
        },
        '&:hover': {
            color: `${theme.palette.primary.main} !important`,
        },
        fontWeight: 'bold',
        transition: 'color .3s ease',
    }),
};

const MuiTab: Components<Theme>['MuiTab'] = { styleOverrides: tabCss };

export default MuiTab;
