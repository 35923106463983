import { Components, Theme } from '@mui/material';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

const autocompleteCss: ComponentsOverrides<Theme>['MuiAutocomplete'] = {
    inputRoot: {
        padding: '7.5px',
    },
    listbox: {
        '&::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: '#e2d0b6',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b17c43',
            borderRadius: '15px',
        },
    },
};

const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = { styleOverrides: autocompleteCss };

export default MuiAutocomplete;
