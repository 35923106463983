import FilterListIcon from '@mui/icons-material/FilterList';
import { FilterType, ROTATION_FIELDS_FILTERS } from '@modules/encoding/modules/rotation/store/filter.slice';
import { MenuItem } from '@mui/material';
import { Select, Tooltip } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { useFieldFiltersStyles } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldListFilters/FieldFilters.style';
import { IconMap2, IconSeed, IconCheckCircle, IconCross } from '@soil-capital/ui-kit/icons';

const FILTERS_TRANSLATIONS = {
    [ROTATION_FIELDS_FILTERS.NO_FILTER]: 'constants.filter',
    [ROTATION_FIELDS_FILTERS.FIELDS_WITHOUT_CROP]: 'constants.fields-no-crop',
    [ROTATION_FIELDS_FILTERS.FIELDS_WITH_CROP]: 'constants.fields-with-crop',
    [ROTATION_FIELDS_FILTERS.FIELDS_SELECTED]: 'constants.fields-selected',
};

type FieldListFiltersProps = {
    value: FilterType;
    onChange: (value: FilterType) => void;
    onReset: () => void;
};

export const FieldListFilters = ({ value, onChange, onReset }: FieldListFiltersProps) => {
    const { t } = useTranslation();
    const { classes, cx } = useFieldFiltersStyles();
    const hasNoFilter = value === ROTATION_FIELDS_FILTERS.NO_FILTER;

    return (
        <Select
            className={cx(!hasNoFilter && classes.selectedValue)}
            classes={{ select: classes.select }}
            id="rotation-filters"
            placeholder={t('constants.filter')}
            onChange={(e) => onChange(e.target.value as FilterType)}
            value={value}
            endAdornment={
                <Tooltip className={classes.tooltip} text={t('constants.delete-filter')}>
                    <IconCross className={cx(classes.reset, hasNoFilter && classes.hide)} onClick={onReset} />
                </Tooltip>
            }
            renderValue={(v) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                    <FilterListIcon />
                    <span className={cx(v === ROTATION_FIELDS_FILTERS.NO_FILTER && classes.placeholder)}>
                        {t(FILTERS_TRANSLATIONS[v as FilterType])}
                    </span>
                </div>
            )}
        >
            <MenuItem sx={{ display: 'none' }} value={ROTATION_FIELDS_FILTERS.NO_FILTER} disabled>
                {t('constants.filter')}
            </MenuItem>
            <MenuItem className={classes.filterItem} value={ROTATION_FIELDS_FILTERS.FIELDS_WITHOUT_CROP}>
                <IconMap2 className={classes.filterIcon} /> <span>{t('constants.fields-no-crop')}</span>
            </MenuItem>
            <MenuItem className={classes.filterItem} value={ROTATION_FIELDS_FILTERS.FIELDS_WITH_CROP}>
                <IconSeed className={classes.filterIcon} /> <span>{t('constants.fields-with-crop')}</span>
            </MenuItem>
            <MenuItem className={classes.filterItem} value={ROTATION_FIELDS_FILTERS.FIELDS_SELECTED}>
                <IconCheckCircle className={classes.filterIcon} /> <span>{t('constants.fields-selected')}</span>
            </MenuItem>
        </Select>
    );
};
