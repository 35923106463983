import {
    GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_FAILED,
    GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_LOADING,
    GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_SUCCESS,
} from '../../actions/FarmSeasonFieldActions/GetFarmSeasonFieldsPreviousHarvestTypes';

import {
    FarmSeasonFieldPreviousHarvestReducerStateT,
    FarmSeasonFieldPreviousHarvestT,
} from './FarmSeasonFieldPreviousHarvestReducerTypes';
import { Reducer } from '@reduxjs/toolkit';
import { AppActionsT } from '../../actions/AppActions/AppActionsTypes';

export const defaultFarmSeasonFieldsPreviousHarvestState: FarmSeasonFieldPreviousHarvestReducerStateT = {
    loaded: [],
    loading: false,
    error: undefined,
    historyList: [],
};

const FarmSeasonFieldsPreviousHarvestReducer: Reducer<FarmSeasonFieldPreviousHarvestReducerStateT, AppActionsT> = (
    state,
    action,
): FarmSeasonFieldPreviousHarvestReducerStateT => {
    if (!state) {
        return defaultFarmSeasonFieldsPreviousHarvestState;
    }

    switch (action.type) {
        case GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_LOADING:
            return {
                ...state,
                loading: true,
            };
        case GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_SUCCESS:
            const existingFieldIds = state.historyList.map((item) => item.initial_field_id);
            const listData = [
                ...state.historyList,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...(action as any).payload?.history.filter(
                    (item: FarmSeasonFieldPreviousHarvestT) => !existingFieldIds.includes(item.initial_field_id),
                ),
            ];

            return {
                ...state,
                loading: false,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                loaded: [...state.loaded, (action as any).initial_field_id],
                historyList: listData,
            };
        case GET_FARM_SEASON_FIELDS_PREVIOUS_HARVEST_FAILED:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default FarmSeasonFieldsPreviousHarvestReducer;
