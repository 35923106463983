import { getHasExistingToken, removeHasAccessTokenCookie } from '@modules/auth/utils/getHasAccessTokenCookie';
import LoaderFullScreen from '@shared/components/LoaderFullScreen/LoaderFullScreen';
import useCurrentLanguage from '@shared/hooks/useCurrentLanguage';
import { ReactElement, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import authActions from '../../store/authActions';
import authSelectors from '../../store/authSelectors';

export const AuthenticatedRoutesGuard = ({
    authenticatedComponent,
    unauthenticatedComponent,
}: {
    authenticatedComponent: ReactElement;
    unauthenticatedComponent: ReactElement;
}): ReactElement => {
    const { validateCookie, validateCookieLoading } = authActions.useValidateCookie();
    const isAuthenticated = authSelectors.useIsAuthenticated();
    const hasAccessTokenCookie = getHasExistingToken().hasAccessTokenCookie;
    const hasAccessToken = getHasExistingToken().hasAccessTokenCookie;
    const shouldRedirectToV2 = authSelectors.useShouldRedirectToV2();
    const currentLanguage = useCurrentLanguage();
    const { i18n } = useTranslation();

    authActions.useKeepUrlToRedirect(hasAccessTokenCookie);

    useEffect(() => {
        if (hasAccessToken) {
            validateCookie().catch((e) => {
                if (e.status === 401) {
                    removeHasAccessTokenCookie();
                }
                console.error('ERROR: ', e.status);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let renderingContent: ReactNode;

    if (isAuthenticated) {
        if (shouldRedirectToV2) {
            window.location.href = process.env.REACT_APP_URL_V2 ?? '';
            renderingContent = null;
        } else {
            // access the authenticated part of the app
            renderingContent = authenticatedComponent;
            // set the user's language
            if (i18n.language !== currentLanguage?.iso_code) {
                i18n.changeLanguage(currentLanguage?.iso_code);
            }
        }
    } else if (!isAuthenticated && hasAccessToken) {
        // wait for the validateCookie fetch, as the `has_access_token` cookie is set
        renderingContent = null;
    } else if (!isAuthenticated) {
        renderingContent = unauthenticatedComponent;
    }

    return <LoaderFullScreen isLoading={validateCookieLoading}>{renderingContent}</LoaderFullScreen>;
};
