import seasonApi from './season.api';
import { seasonCacheAdapter } from './season.cache';
import { SeasonEndpointParamsT } from './season.types';
import createDefaultState from '../createDefaultState';

export default ({ farmId }: SeasonEndpointParamsT, options?: { skip?: boolean }) => {
    const defaultState = createDefaultState(seasonCacheAdapter, () =>
        seasonApi.useGet({ farmId }, { skip: options?.skip }),
    );

    return {
        seasonState: {
            ...defaultState,
        },
    };
};
