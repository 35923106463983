import { makeStyles } from 'tss-react/mui';

export const useResultsCalculationDetailStyles = makeStyles()((theme) => ({
    calculDetail: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    calculDetailIcon: {
        color: theme.palette.darkScale[700],
    },
}));
