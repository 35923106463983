import {
    NotificationActionTypes,
    NotificationActionsT,
} from '../../actions/NotificationActions/NotificationActionsTypes';
import { GetResetT, RESET } from '../../reducers/RootTypes';
import { Reducer } from '@reduxjs/toolkit';

import { NotificationReducerStateT } from './NotificationReducerTypes';

const notificationConfig = {
    silentFetchInterval: 15000,
    initialFetchLimit: 6,
    fetchMoreLimit: 10,
    snackbarDuration: 10000,
};

export const defaultNotificationState: NotificationReducerStateT = {
    fetchNotifLoading: false,
    fetchMoreNotifLoading: false,
    markAllNotifAsReadLoading: false,
    markNotifAsReadLoadings: [],
    notificationsList: [],
    notificationsCount: 0,
    unreadNotificationsCount: 0,
    snackbarStack: [],
};

/** get new array of notifications with added/removed id (= mark notif as read loading) */
const getMarkNotifAsReadLoadings = (notifLoadings: string[], id: string, action: 'add' | 'remove'): string[] => {
    const markNotifAsReadLoadings = [...notifLoadings];
    const notifLoadingIndex = markNotifAsReadLoadings.findIndex((notifId) => notifId === id);

    if (action === 'add' && notifLoadingIndex === -1) {
        markNotifAsReadLoadings.splice(notifLoadingIndex, 1, id);
    } else if (action === 'remove' && notifLoadingIndex !== -1) {
        markNotifAsReadLoadings.splice(notifLoadingIndex, 1);
    }

    return markNotifAsReadLoadings;
};

const notificationReducer: Reducer<NotificationReducerStateT, NotificationActionsT | GetResetT> = (
    state,
    action,
): NotificationReducerStateT => {
    if (!state) {
        return defaultNotificationState;
    }

    switch (action.type) {
        /* ---------------------------------- Fetch --------------------------------- */
        case NotificationActionTypes.FETCH:
            return { ...state, fetchNotifLoading: true };
        case NotificationActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                fetchNotifLoading: false,
                notificationsList: [...action.payload.notifications],
                notificationsCount: action.payload.notificationsCount,
                unreadNotificationsCount: action.payload.unreadNotificationsCount,
            };
        case NotificationActionTypes.FETCH_FAIL:
            return { ...state, fetchNotifLoading: false };
        /* -------------------------------------------------------------------------- */

        /* --------------------------- Fetch more --------------------------- */
        case NotificationActionTypes.FETCH_MORE:
            return { ...state, fetchMoreNotifLoading: true };
        case NotificationActionTypes.FETCH_MORE_SUCCESS:
            return {
                ...state,
                fetchMoreNotifLoading: false,
                notificationsList: [...state.notificationsList, ...action.payload.notifications],
                notificationsCount: action.payload.notificationsCount,
                unreadNotificationsCount: action.payload.unreadNotificationsCount,
            };
        case NotificationActionTypes.FETCH_MORE_FAIL:
            return { ...state, fetchMoreNotifLoading: false };
        /* -------------------------------------------------------------------------- */

        /* --------------------------- Fetch silent --------------------------- */
        case NotificationActionTypes.FETCH_SILENT_SUCCESS: {
            const notificationsList = [...state.notificationsList];
            const newSnackbarStack = [...state.snackbarStack];

            action.payload.notifications.reverse().forEach((notifToSync) => {
                const updatedNotifIndex = notificationsList.findIndex((notif) => notif.id === notifToSync.id);

                // replace updated notif
                if (updatedNotifIndex > -1) {
                    notificationsList.splice(updatedNotifIndex, 1, notifToSync);
                } else {
                    notificationsList.unshift(notifToSync);
                    newSnackbarStack.push({ ...notifToSync });

                    if (notificationsList.length > notificationConfig.initialFetchLimit) {
                        notificationsList.pop();
                    }
                }
            });

            return {
                ...state,
                notificationsList,
                notificationsCount: action.payload.notificationsCount,
                unreadNotificationsCount: action.payload.unreadNotificationsCount,
                snackbarStack: newSnackbarStack,
            };
        }
        /* -------------------------------------------------------------------------- */

        /* ------------------------------ Mark as read ------------------------------ */
        case NotificationActionTypes.MARK_AS_READ: {
            return {
                ...state,
                markNotifAsReadLoadings: getMarkNotifAsReadLoadings(
                    state.markNotifAsReadLoadings,
                    action.payload.id,
                    'add',
                ),
            };
        }
        case NotificationActionTypes.MARK_AS_READ_SUCCESS: {
            const newNotificationsList = [...state.notificationsList];
            let newUnreadNotificationCount = state.unreadNotificationsCount;
            const updatedNotifIndex = newNotificationsList.findIndex(
                (notif) => notif.id === action.payload.notification.id,
            );

            // replace updated notif
            if (updatedNotifIndex !== -1) {
                newNotificationsList.splice(updatedNotifIndex, 1, action.payload.notification);
                newUnreadNotificationCount -= 1;
            }

            return {
                ...state,
                markNotifAsReadLoadings: getMarkNotifAsReadLoadings(
                    state.markNotifAsReadLoadings,
                    action.payload.notification.id,
                    'remove',
                ),
                notificationsList: newNotificationsList,
                unreadNotificationsCount: newUnreadNotificationCount,
            };
        }
        case NotificationActionTypes.MARK_AS_READ_FAIL: {
            return {
                ...state,
                markNotifAsReadLoadings: getMarkNotifAsReadLoadings(
                    state.markNotifAsReadLoadings,
                    action.payload.id,
                    'remove',
                ),
            };
        }
        /* -------------------------------------------------------------------------- */

        /* ---------------------------- Mark all as read --------------------------- */
        case NotificationActionTypes.MARK_ALL_AS_READ: {
            return {
                ...state,
                markAllNotifAsReadLoading: true,
            };
        }
        case NotificationActionTypes.MARK_ALL_AS_READ_SUCCESS: {
            return {
                ...state,
                markAllNotifAsReadLoading: false,
                notificationsList: state.notificationsList.map((notif) => ({
                    ...notif,
                    read_at: notif.read_at ?? new Date().toISOString(),
                })),
                unreadNotificationsCount: 0,
            };
        }
        case NotificationActionTypes.MARK_ALL_AS_READ_FAIL: {
            return {
                ...state,
                markAllNotifAsReadLoading: false,
            };
        }
        /* -------------------------------------------------------------------------- */

        /* ------------------------------ Snackbar Stack ---------------------------- */
        case NotificationActionTypes.SHIFT_SNACKBAR_STACK: {
            const newSnackbarStack = [...state.snackbarStack];
            newSnackbarStack.shift();

            return {
                ...state,
                snackbarStack: newSnackbarStack,
            };
        }
        /* -------------------------------------------------------------------------- */

        case RESET:
            return {
                ...defaultNotificationState,
            };

        default:
            return state;
    }
};

export default notificationReducer;
