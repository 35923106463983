import { LanguageT } from '../../reducers/StaticReducer/StaticReducerType';

export const LANGUAGES_GET_LOADING = 'LANGUAGES_GET_LOADING';
export const LANGUAGES_GET_SUCCESS = 'LANGUAGES_GET_SUCCESS';
export const LANGUAGES_GET_FAILED = 'LANGUAGES_GET_FAILED';

export type LanguagesGetSuccessDataT = LanguageT[];

export type LanguagesGetFailedDataT = {
    message?: string;
    list?: string[];
};

export type LanguagesGetLoadingT = {
    type: typeof LANGUAGES_GET_LOADING;
};

export type LanguagesGetSuccesT = {
    type: typeof LANGUAGES_GET_SUCCESS;
    payload: LanguagesGetSuccessDataT;
};

export type LanguagesGetFailedT = {
    type: typeof LANGUAGES_GET_FAILED;
    error: LanguagesGetFailedDataT;
};

export type LanguagesGetDispatchTypes = LanguagesGetLoadingT | LanguagesGetSuccesT | LanguagesGetFailedT;
