import { TranslationT } from '../../reducers/StaticReducer/StaticReducerType';
import { Dispatch } from 'react';

export const TRANSLATION_GET_LOADING = 'TRANSLATION_GET_LOADING';
export const TRANSLATION_GET_SUCCESS = 'TRANSLATION_GET_SUCCESS';
export const TRANSLATION_GET_FAILED = 'TRANSLATION_GET_FAILED';

export type TranslationGetSuccessDataT = TranslationT;

export type TranslationGetFailedDataT = {
    message?: string;
    list?: string[];
};

export type TranslationGetLoadingT = {
    type: typeof TRANSLATION_GET_LOADING;
};

export type TranslationGetSuccessT = {
    type: typeof TRANSLATION_GET_SUCCESS;
    payload: {
        iso_code: string;
        translation: TranslationGetSuccessDataT;
    };
};

export type TranslationGetFailedT = {
    type: typeof TRANSLATION_GET_FAILED;
    error: TranslationGetFailedDataT;
};

export type TranslationDispatchTypes = TranslationGetLoadingT | TranslationGetSuccessT | TranslationGetFailedT;

export type TranslationGetCallBackT = (
    dispatch: Dispatch<TranslationDispatchTypes>,
) => Promise<TranslationGetFailedDataT>;
