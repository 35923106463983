import { MixinsOptions } from '@mui/material/styles/createMixins';

const mixins: MixinsOptions = {
    toolbar: {
        minHeight: 56,
        '@media (min-width:0px) and (orientation: landscape)': { minHeight: 48 },
        '@media (min-width:600px)': { minHeight: 100 },
    },
};
export default mixins;
