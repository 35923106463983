import { useGetXeroPdfQuery } from '@modules/billing/api/billingApi';
import { useEffect, useState } from 'react';
import { PaymentStepTimelineInvoiceLogicPropsT } from '../PaymentStepTimeline.types';

export const usePaymentStepTimelineInvoiceLogic = ({
    farmSeasonProductPlanId,
    invoiceId,
}: PaymentStepTimelineInvoiceLogicPropsT) => {
    const [openPdf, setOpenPdf] = useState(false);
    const { data: pdf, isLoading: isPdfLoading } = useGetXeroPdfQuery(
        {
            farmSeasonProductPlan: farmSeasonProductPlanId,
            invoiceId: invoiceId || '',
        },
        { skip: !openPdf },
    );
    useEffect(() => {
        if (pdf) {
            const url = URL.createObjectURL(pdf);
            window.open(url, '_blank');
        }
    }, [pdf]);

    const openPdfInNewTab = () => {
        setOpenPdf(true);
    };

    return {
        openPdfInNewTab,
        isPdfLoading,
    };
};
