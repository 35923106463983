import { MenuItem, Select } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { useSelectSeasonsStyles } from './SelectSeasons.style';
import { useSelectSeasonsLogic } from '@modules/encoding/modules/layout/components/SelectSeasons/useSelectSeasonsLogic';

type SelectSeasonsProps = {
    className?: string;
};

export const SelectSeasons = ({ className }: SelectSeasonsProps) => {
    const { t } = useTranslation();
    const { classes, cx } = useSelectSeasonsStyles();
    const { isLoading, handleSeasonChanged, options, getFsHarvestYear, defaultValue } = useSelectSeasonsLogic();

    if (isLoading) return <Skeleton variant="rounded" width={150} height={40} />;

    return (
        <Select
            name="season"
            className={cx(classes.select, className)}
            placeholder={t('constants.select')}
            onChange={handleSeasonChanged}
            defaultValue={defaultValue}
            renderValue={(value) => `${t('db.operation_type.harvest')} ${getFsHarvestYear(Number(value))}`}
        >
            <MenuItem sx={{ display: 'none' }} value="" disabled>
                {t('constants.select')}
            </MenuItem>
            {options?.map((season) => (
                <MenuItem key={season.id} value={season.id.toString()}>
                    {`${t('db.operation_type.harvest')} ${season.harvest_year}`}
                </MenuItem>
            ))}
        </Select>
    );
};
