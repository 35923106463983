import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Location } from 'react-router-dom';
import { UserT } from '../api/authApi.types';

export interface AuthStateT {
    authUser: UserT | null;
    farmerInfos: {
        latestActiveSeasonId: number | null;
        modelType: 'cft' | 'dndc' | null;
    } | null;
    /** url who need authentication. It is keeped here until the user log in */
    urlToRedirect: Location | null;
}

const initialState: AuthStateT = { authUser: null, urlToRedirect: null, farmerInfos: null };

export default createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthUser(state, action: PayloadAction<AuthStateT['authUser']>) {
            state.authUser = action.payload;
        },

        mergeAuthUser(state, action: PayloadAction<Partial<AuthStateT['authUser']>>) {
            if (state.authUser === null) {
                return;
            }
            state.authUser = { ...state.authUser, ...action.payload };
        },

        setFarmerInfos(state, action: PayloadAction<AuthStateT['farmerInfos']>) {
            state.farmerInfos = action.payload;
        },

        keepUrlToRedirect(state, action: PayloadAction<AuthStateT['urlToRedirect']>) {
            state.urlToRedirect = action.payload;
        },
    },
});
