import { TillagePracticeT } from '../../reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const TILLAGE_PRACTICE_LIST_GET_LOADING = 'TILLAGE_PRACTICE_LIST_GET_LOADING';
export const TILLAGE_PRACTICE_LIST_GET_SUCCESS = 'TILLAGE_PRACTICE_LIST_GET_SUCCESS';
export const TILLAGE_PRACTICE_LIST_GET_FAILED = 'TILLAGE_PRACTICE_LIST_GET_FAILED';

export type TillagePracticeListGetSuccessDataT = TillagePracticeT[];

export type TillagePracticeListGetLoadingT = {
    type: typeof TILLAGE_PRACTICE_LIST_GET_LOADING;
};

export type TillagePracticeListGetSuccessT = {
    type: typeof TILLAGE_PRACTICE_LIST_GET_SUCCESS;
    payload: TillagePracticeListGetSuccessDataT;
};

export type TillagePracticeListGetFailedT = {
    type: typeof TILLAGE_PRACTICE_LIST_GET_FAILED;
    error: string;
};

export type TillagePracticeListGetDispatchType =
    | TillagePracticeListGetLoadingT
    | TillagePracticeListGetSuccessT
    | TillagePracticeListGetFailedT;

export type TillagePracticeListGetCallback = (
    dispatch: Dispatch<TillagePracticeListGetDispatchType>,
) => Promise<boolean>;
