import authSelectors from './store/authSelectors';
import authActions from './store/authActions';
import { makeDispatchHook } from '@shared/utils';
import authSlice from './store/authSlice';
import { usePermission } from './hooks/usePermission';

export const auth = {
    useAuthUser: authSelectors.useAuthUser,
    useLogout: authActions.useLogout,
    useChangeLanguage: authActions.useChangeLanguage,
    useMergeAuthUser: makeDispatchHook(authSlice.actions.mergeAuthUser),
    useLatestActiveSeasonId: authSelectors.useLatestActiveSeasonId,
    useUpdateFarmerInfos: authActions.useUpdateFarmerInfos,
    usePermission,
};
