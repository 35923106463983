import { CSSObject } from 'tss-react';
import palette from './palette';

type styleKeys = 'scrollSection';

const styles: Record<styleKeys, CSSObject> = {
    scrollSection: {
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: palette.deprecated_spanishWhite.main,
            borderRadius: '3px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: palette.deprecated_driftwood.light,
            borderRadius: '5px',
        },
    },
};

export default styles;
