import { Paper } from '@mui/material';
import React from 'react';
import useInfoPaperStyle from './InfoPaper.style';
import { Typography } from '@soil-capital/ui-kit/material-core';

const InfoPaper: React.FC<{ children: React.ReactNode; title: string }> = ({ children, title }) => {
    const { classes } = useInfoPaperStyle();

    return (
        <Paper className={classes.container} variant="elevation" elevation={0}>
            <Typography className={classes.title}>{title}</Typography>
            {children}
        </Paper>
    );
};

export default InfoPaper;
