import { makeStyles } from 'tss-react/mui';

export const usePaymentStepsStyle = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(4),
        width: '680px',
    },
}));
