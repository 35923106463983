import { FarmSeasonFieldAgroforestryT } from '../../reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_LOADING =
    'POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_LOADING';
export const POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_FAILED = 'POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_FAILED';
export const POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_SUCCESS =
    'POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_SUCCESS';

export type PostFarmSeasonFieldAgroforestryValuesDataT = {
    agroforestryValues: Optional<Omit<FarmSeasonFieldAgroforestryT, 'farm_season_field_id'>, 'id'>[];
};

export type PostFarmSeasonFieldAgroforestryValuesResponseDataT = {
    farm_season_field_id: number;
    agroforestry: FarmSeasonFieldAgroforestryT[];
    installation: {
        onboarding: {
            finished: string[];
            agroforestry: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsAgroforestryValuesPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldAgroforestryValuesDispatchTypesT>,
) => Promise<PostFarmSeasonFieldAgroforestryValuesResponseErrorT>;

export type PostFarmSeasonFieldAgroforestryValuesResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldAgroforestryValuesLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_LOADING;
};

export type PostFarmSeasonFieldAgroforestryValuesFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_FAILED;
    error: PostFarmSeasonFieldAgroforestryValuesResponseErrorT;
};

export type PostFarmSeasonFieldAgroforestryValuesSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_SUCCESS;
    // TODO: Define data type
    payload: any; // eslint-disable-line
};

export type PostFarmSeasonFieldAgroforestryValuesDispatchTypesT =
    | PostFarmSeasonFieldAgroforestryValuesLoadingT
    | PostFarmSeasonFieldAgroforestryValuesFailedT
    | PostFarmSeasonFieldAgroforestryValuesSuccessT;
