import { FertiliserT } from '../../reducers/FertiliserReducer/FertiliserReducerTypes';

export const FERTILISERS_CREATE_LOADING = 'FERTILISERS_CREATE_LOADING';
export const FERTILISERS_CREATE_SUCCESS = 'FERTILISERS_CREATE_SUCCESS';
export const FERTILISERS_CREATE_FAILED = 'FERTILISERS_CREATE_FAILED';

export type FertilisersCreateSuccesT = {
    type: typeof FERTILISERS_CREATE_SUCCESS;
    payload: FertiliserT;
};

export type FertilisersCreateDispatchTypes = FertilisersCreateSuccesT;
