import { Components, ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { IconCheckbox, IconCheckboxChecked } from '@soil-capital/ui-kit/icons';
import React from 'react';

const CheckboxCss: ComponentsOverrides<Theme>['MuiCheckbox'] = {
    root: ({ theme }) => ({
        '&.Mui-checked': {
            fill: theme.palette.deprecated_driftwood.light,
            '& svg': {
                backgroundColor: theme.palette.deprecated_spanishWhite.light,
                borderRadius: '5px',
                border: `1px solid ${theme.palette.deprecated_driftwood.light}`,
            },
        },
        '& svg': {
            borderRadius: '5px',
            border: `1px solid ${theme.palette.grey['300']}`,
        },
    }),
};

const CheckboxProperties: ComponentsProps['MuiCheckbox'] = {
    color: 'primary',
    checkedIcon: React.createElement(IconCheckboxChecked),
    icon: React.createElement(IconCheckbox),
};

const MuiCheckbox: Components<Theme>['MuiCheckbox'] = { styleOverrides: CheckboxCss, defaultProps: CheckboxProperties };

export default MuiCheckbox;
