import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import ForgotPasswordPageView from './ForgotPasswordPageView';
import { ForgotPasswordDataT } from './ForgotPasswordPageTypes';
import { useNavigate } from 'react-router';
import authApi from '@modules/auth/api/authApi';

export const ForgotPasswordPage = (): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [msgOpen, setMsgOpen] = useState(false);
    const [sendResetPasswordLink, { isLoading: sendResetPasswordLinkLoading }] =
        authApi.useSendResetPasswordLinkMutation();

    const handleMsgOpen = (): void => {
        setMsgOpen(true);
    };

    useEffect(() => {
        if (msgOpen) {
            setTimeout(() => {
                navigate('../login');
            }, 8000);
        }
    }, [msgOpen, navigate]);

    const validationSchema = yup.object({
        email: yup.string().email(t('validation.invalid-email')).required(t('validation.no-email-provided')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: ForgotPasswordDataT, { setSubmitting, setErrors, resetForm }) => {
            sendResetPasswordLink(values)
                .unwrap()
                .then(() => {
                    resetForm();
                    handleMsgOpen();
                })
                .catch((error) => {
                    const error_code = error.data.code?.replace('validation.', '');
                    const error_string = error_code ? t(`forgot-password.validation.${error_code}`) : error.data.error;

                    setErrors({
                        email: error_string,
                    });
                })
                .finally(() => setSubmitting(false));
        },
    });

    return <ForgotPasswordPageView formik={formik} loading={sendResetPasswordLinkLoading} msgOpen={msgOpen} t={t} />;
};
