/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from '@mui/material';
import { useMemo, useRef } from 'react';

/**
 * Creates a debounce function for a variable 'fn'. Maintains consistent reference across React re-renders, ensuring uniqueness even if 'fn' changes
 */
export const useStableDebounce = <T extends (...params: any[]) => any>(fn: T, dueTime: number) => {
    const submitRef = useRef(fn);
    submitRef.current = fn;

    const stableDebounceFn = useMemo(
        () => debounce((...p: Parameters<T>) => submitRef.current(...p), dueTime),
        [dueTime],
    );
    return stableDebounceFn;
};
