import {
    SUBSCRIPTION_SAVE_FARM_FAILED,
    SUBSCRIPTION_SAVE_FARM_LOADING,
    SUBSCRIPTION_SAVE_FARM_SUCCESS,
} from '../../actions/SubscriptionActions/SubscriptionSaveFarmTypes';
import { RESET } from '../../reducers/RootTypes';
import { AppActionsT } from '../../actions/AppActions/AppActionsTypes';
import { Reducer } from '@reduxjs/toolkit';

import { SubscriptionReducerT } from './SubscriptionReducerTypes';

export const defaultSubscriptionState: SubscriptionReducerT = {
    saveFarmLoading: false,
    saveFarmSuccess: false,
    saveFarmError: null,
    tacSigned: null,
};

const subscriptionReducer: Reducer<SubscriptionReducerT, AppActionsT> = (state, action): SubscriptionReducerT => {
    if (!state) {
        return defaultSubscriptionState;
    }

    switch (action.type) {
        case SUBSCRIPTION_SAVE_FARM_LOADING:
            return { ...state, saveFarmLoading: true };
        case SUBSCRIPTION_SAVE_FARM_SUCCESS:
            return { ...state, saveFarmLoading: false, saveFarmSuccess: true };
        case SUBSCRIPTION_SAVE_FARM_FAILED:
            return { ...state, saveFarmLoading: false, saveFarmError: action.error };
        case RESET:
            return {
                ...defaultSubscriptionState,
            };
        default:
            return state;
    }
};

export default subscriptionReducer;
