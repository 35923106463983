import { makeStyles } from 'tss-react/mui';

export const useResultsSummaryStyle = makeStyles()((theme) => ({
    timelineContainer: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(9),
        marginLeft: theme.spacing(12),
        marginRight: theme.spacing(12),
    },
    headerCta: {
        marginLeft: 'auto',
    },
}));
