import LabelTextField from '../LabelTextField/LabelTextField';
import { useState } from 'react';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import useLabelPasswordFieldStyle from './LabelPasswordField.style';
import { LabelPasswordFieldPropsT } from './LabelPasswordFIeldTypes';

const LabelPasswordField = ({
    autoComplete,
    disabled,
    error,
    helperText,
    id,
    label,
    name,
    value,
    variant,
    fullWidth,
    required,
    onChange,
    onBlur,
    onFocus,
}: LabelPasswordFieldPropsT): JSX.Element => {
    const { classes } = useLabelPasswordFieldStyle();
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

    return (
        <LabelTextField
            autoComplete={autoComplete}
            disabled={disabled}
            endAdornment={
                <EyeIcon
                    className={classes.eyeIcon}
                    onMouseDown={() => setPasswordVisible(true)}
                    onMouseLeave={() => setPasswordVisible(false)}
                    onMouseUp={() => setPasswordVisible(false)}
                />
            }
            error={error}
            fullWidth={fullWidth}
            helperText={helperText}
            id={id}
            label={label}
            name={name}
            required={required}
            type={passwordVisible ? 'text' : 'password'}
            value={value}
            variant={variant}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
        />
    );
};

export default LabelPasswordField;
