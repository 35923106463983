import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useLoginPageStyle = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        minHeight: '600px',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 'none',
        padding: 0,
        backgroundColor: alpha(theme.palette.secondary.light, 0.15),
    },
    logoImg: {
        maxWidth: '140px',
        marginBottom: '40px',
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '1.5em',
        marginBottom: '30px',
    },
    checkbox: {
        '& > div': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    forgotLink: {},
    signUpLink: {
        textDecoration: 'underline',
    },

    centerContent: {
        margin: 'auto',
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    buttonLight: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 8px 10px 1px rgb(0 0 0 / 5%), 0px 3px 14px 2px rgb(0 0 0 / 5%);',
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
            boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%);',
        },
    },

    sideZoneImage: {
        backgroundImage: "url('/assets/images/landscape-fields-original-min.jpg')",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '15px',
        [theme.breakpoints.up('md')]: {
            borderRadius: '0px',
        },
        width: '100%',
        height: '100%',
    },
}));

export default useLoginPageStyle;
