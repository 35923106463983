import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

import { ColorNameT } from './DividerTextTypes';

const useDividerTextStyle = makeStyles<{ colorName?: ColorNameT }>()((theme, { colorName }) => ({
    dividerContainer: {
        display: 'flex',
        alignItems: 'center',
        color: colorName ? theme.palette[colorName]?.main : theme.palette.grey[400],
        margin: '15px 0',
        '&>span': {
            marginLeft: '15px',
            marginRight: '15px',
        },
        '&>hr': {
            flexGrow: 1,
            background: alpha(colorName ? theme.palette[colorName]?.main : theme.palette.grey[400], 0.5),
        },
    },
    dividerText: {
        color: colorName ? theme.palette[colorName]?.main : theme.palette.grey[400],
    },
}));

export default useDividerTextStyle;
