import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import CloseIcon from '@mui/icons-material/Close';

import useTOSModalViewStyle from './TOSModalView.style';
import { TOSModalViewPropsT } from './TOSModalViewTypes';

const TOSModalView = ({ className, onClose }: TOSModalViewPropsT): JSX.Element => {
    const { classes } = useTOSModalViewStyle();
    const { i18n } = useTranslation();

    return (
        <Paper className={`${className} ${classes.paper}`} elevation={0}>
            <div className={classes.container}>
                {i18n.language !== 'fr' ? (
                    <Document className={classes.documentEN} file="./terms-and-conditions/tos_en.pdf">
                        <Page pageNumber={1} scale={1.3} />
                        <Page className={`${classes.pdfMargin}`} height={900} pageNumber={2} scale={1.3} width={600} />
                    </Document>
                ) : (
                    <Document className={classes.documentFR} file="./terms-and-conditions//tos_fr.pdf">
                        <Page pageNumber={1} scale={1.3} />
                        <Page className={classes.pdfMargin} pageNumber={2} scale={1.3} />
                        <Page className={`${classes.pdfMarginLastFR}`} pageNumber={3} scale={1.3} />
                    </Document>
                )}
            </div>
            <CloseIcon className={classes.close} onClick={onClose} />
        </Paper>
    );
};

export default TOSModalView;
