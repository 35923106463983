import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import SignUpPageView from './SignUpPageView';
import authActions from '@modules/auth/store/authActions';
import useCurrentLanguage from '@shared/hooks/useCurrentLanguage';

export const SignUpPage = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    const { register, registerLoading } = authActions.useRegister();
    // const auth0Loading = useSelector((state: AppStateT) => state.auth.auth0Loading);

    const currentLanguage = useCurrentLanguage();
    const { t } = useTranslation();

    const [passwordRules, setPasswordRules] = useState<{ isValid: boolean; text: string | JSX.Element }[]>([]);

    const minSpecialCharacterMatch = useMemo(() => /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, []);
    const minDigitMatch = useMemo(() => /^(?=.*[0-9])/, []);
    const minLength = 8;

    const validationSchema = yup.object({
        first_name: yup
            .string()
            .min(2, t('validation.too-short'))
            .max(50, t('validation.too-long'))
            .required(t('validation.no-first-name-provided')),
        last_name: yup
            .string()
            .min(2, t('validation.too-short'))
            .max(50, t('validation.too-long'))
            .required(t('validation.no-last-name-provided')),
        email: yup.string().email(t('validation.invalid-email')).required(t('validation.no-email-provided')),
        password: yup
            .string()
            .required(t('validation.no-password-provided'))
            .min(minLength, t('validation.password-must-be-at-least-x-characters', { min: minLength }))
            .matches(minDigitMatch, t('validation.password-must-at-least-contain-one-figure'))
            .matches(minSpecialCharacterMatch, t('validation.password-must-at-least-contain-one-special-character')),
        terms: yup.bool().oneOf([true], t('validation.signup.terms-accept')),
        referral_code: yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            first_name: searchParams.get('firstName') ?? '',
            last_name: searchParams.get('lastName') ?? '',
            email: searchParams.get('email') ?? '',
            referral_code: '',
            password: '',
            terms: false,
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async ({ email, first_name, last_name, password, referral_code }, { setSubmitting, setErrors }) => {
            register({
                email: email.toLowerCase(),
                first_name,
                last_name,
                password,
                referral_code,
                language_id: currentLanguage?.id ?? 1,
                token: searchParams.get('token') ?? undefined,
                invitation_id: Number(searchParams.get('invitationId')) ?? undefined,
            })
                .catch((error) => {
                    const emailTaken = error.data.message.indexOf('The email has already been taken.') >= 0;
                    setErrors({
                        email: emailTaken
                            ? t('pages.signup.the-email-has-already-been-taken')
                            : error.data.errors?.email
                            ? t(`validation.${error.data.code}`) ?? undefined
                            : undefined,
                        first_name: error.data.errors?.first_name
                            ? t(`validation.${error.data.code}`) ?? undefined
                            : undefined,

                        last_name: error.data.errors?.last_name
                            ? t(`validation.${error.data.code}`) ?? undefined
                            : undefined,

                        password: error.data.errors?.password
                            ? t(`validation.${error.data.code}`) ?? undefined
                            : undefined,

                        referral_code:
                            error.data.code?.indexOf('referral_code_not_found') >= 0
                                ? t('pages.signup.referral_code_not_found') ?? undefined
                                : undefined,
                    });
                })
                .then(() => {
                    // redirect to the onboard flow in V2
                    window.location.href = process.env.REACT_APP_URL_V2 || '';
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    useEffect(() => {
        setPasswordRules([
            {
                isValid: formik.values.password?.length >= minLength,
                text: t('validation.password-must-be-at-least-x-characters-short', { min: minLength }),
            },
            {
                isValid: minDigitMatch.test(formik.values.password),
                text: t('validation.password-must-at-least-contain-one-figure-short'),
            },
            {
                isValid: minSpecialCharacterMatch.test(formik.values.password),
                text: t('validation.password-must-at-least-contain-one-special-character-short'),
            },
        ]);
    }, [formik.values.password, minDigitMatch, minSpecialCharacterMatch, t]);

    return (
        <SignUpPageView
            auth0Loading={false /* auth0Loading */}
            formik={formik}
            loading={registerLoading}
            passwordRules={passwordRules}
        />
    );
};
