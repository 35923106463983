import { OrganicFertilizationT } from '../../reducers/FertiliserReducer/FertiliserReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const ORGANIC_FERTILIZATION_LIST_GET_LOADING = 'ORGANIC_FERTILIZATION_LIST_GET_LOADING';
export const ORGANIC_FERTILIZATION_LIST_GET_SUCCESS = 'ORGANIC_FERTILIZATION_LIST_GET_SUCCESS';
export const ORGANIC_FERTILIZATION_LIST_GET_FAILED = 'ORGANIC_FERTILIZATION_LIST_GET_FAILED';

export type OrganicFertilizationListGetSuccessDataT = OrganicFertilizationT[];

export type OrganicFertilizationListGetLoadingT = {
    type: typeof ORGANIC_FERTILIZATION_LIST_GET_LOADING;
};

export type OrganicFertilizationListGetSuccessT = {
    type: typeof ORGANIC_FERTILIZATION_LIST_GET_SUCCESS;
    payload: OrganicFertilizationListGetSuccessDataT;
};

export type OrganicFertilizationListGetFailedT = {
    type: typeof ORGANIC_FERTILIZATION_LIST_GET_FAILED;
    error: string;
};

export type OrganicFertilizationListGetDispatchType =
    | OrganicFertilizationListGetLoadingT
    | OrganicFertilizationListGetSuccessT
    | OrganicFertilizationListGetFailedT;

export type OrganicFertilizationListGetCallback = (
    dispatch: Dispatch<OrganicFertilizationListGetDispatchType>,
) => Promise<boolean>;
