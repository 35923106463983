import i18next from 'i18next';
import { auth } from '@modules/auth';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

export const useUpdateIntercom = () => {
    const user = auth.useAuthUser();
    const { update: intercomUpdate } = useIntercom();

    useEffect(() => {
        intercomUpdate({
            userId: user?.id.toString(),
            email: user?.email,
            name: `${user?.first_name} ${user?.last_name}`,
            languageOverride: i18next?.language,
            customAttributes: {
                userId_Hubspot: user?.hubspot_id,
            },
        });
    }, [user, intercomUpdate]);
};
