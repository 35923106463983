import { makeStyles } from '@soil-capital/ui-kit/style';

const useLoadingViewStyle = makeStyles()(() => ({
    loadingContainer: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loading: {
        color: '#2D525C !important',
    },
}));

export default useLoadingViewStyle;
