import api from '@shared/api';
import {
    PaymentMethodT,
    PostBillingPortalSessionBodyT,
    PostBillingPortalSessionResponseT,
    PaymentDetailsT,
    DiscountT,
} from './billing.types';

export const billingApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentMethod: builder.query<PaymentMethodT, { farmId: number }>({
            query: ({ farmId }) => ({
                url: `/v3/farms/${farmId}/billing/payment-method`,
                method: 'GET',
            }),
        }),
        getPaymentTimeline: builder.query<PaymentDetailsT[], { farmId: number }>({
            query: ({ farmId }) => ({
                url: `/v3/farms/${farmId}/billing/timeline`,
                method: 'GET',
            }),
        }),
        getXeroPdf: builder.query<Blob, { farmSeasonProductPlan: number; invoiceId: string }>({
            query: ({ farmSeasonProductPlan, invoiceId }) => ({
                url: `v3/farm-season-product-plans/${farmSeasonProductPlan}/billing/invoices/${invoiceId}/pdf`,
                method: 'GET',
                responseHandler: (response) => {
                    return response.blob();
                },
            }),
        }),
        getCurrentRenewalDiscount: builder.query<DiscountT, { farmId: number }>({
            query: ({ farmId }) => ({
                url: `/v3/farms/${farmId}/billing/discount`,
                method: 'GET',
            }),
        }),
        postBillingPortalSession: builder.mutation<
            PostBillingPortalSessionResponseT,
            { farmId: number; body: PostBillingPortalSessionBodyT }
        >({
            query: ({ farmId, body }) => ({
                url: `/v3/farms/${farmId}/billing/portal-session`,
                method: 'POST',
                body: body,
            }),
        }),
    }),
});

export const { usePostBillingPortalSessionMutation, useGetXeroPdfQuery } = billingApi;

export const useGetPaymentTimelineQuery = (params: { farmId: number | undefined }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return billingApi.useGetPaymentTimelineQuery({ farmId: params.farmId! }, { skip: !params.farmId });
};

export const useGetPaymentMethodQuery = (params: { farmId: number | undefined }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return billingApi.useGetPaymentMethodQuery({ farmId: params.farmId! }, { skip: !params.farmId });
};

export const useGetCurrentRenewalDiscountQuery = (params: { farmId: number | undefined }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return billingApi.useGetCurrentRenewalDiscountQuery({ farmId: params.farmId! }, { skip: !params.farmId });
};
