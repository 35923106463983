import { LabelNumberFieldPropsT } from './LabelNumberField.types';
import LabelNumberFieldView from './LabelNumberFieldView';

const LabelNumberField = ({
    andormentPosition = 'end',
    andormentText,
    borderColor,
    id,
    required,
    label,
    disabled,
    error,
    fullWidth,
    helperText,
    name,
    value,
    variant,
    onChange,
    withHelp,
    helpName,
    className,
    tooltipSlug,
    inputProps,
    placeholder,
}: LabelNumberFieldPropsT): JSX.Element => {
    const add = () => {
        onChange(Number(value) + 1);
    };

    const minus = () => {
        onChange(Number(value) - 1);
    };

    return (
        <LabelNumberFieldView
            add={add}
            andormentPosition={andormentPosition}
            andormentText={andormentText}
            borderColor={borderColor}
            className={className}
            disabled={disabled}
            error={error}
            fullWidth={fullWidth}
            helpName={helpName}
            helperText={helperText}
            id={id}
            label={label}
            minus={minus}
            name={name}
            required={required}
            tooltipSlug={tooltipSlug}
            value={value}
            variant={variant}
            withHelp={withHelp}
            inputProps={inputProps}
            placeholder={placeholder}
            onChange={(event: any) => {
                const newValue = event.target.value === '' ? event.target.value : Number(event.target.value);
                onChange(newValue);
            }}
            onWheel={(e: any) => (e.target as HTMLInputElement).blur()}
        />
    );
};

export default LabelNumberField;
