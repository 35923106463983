import { Components, Theme } from '@mui/material';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

const appBarCss: ComponentsOverrides<Theme>['MuiAppBar'] = {
    root: {
        backgroundColor: 'transparent !important',
    },
};

const MuiAppBar: Components<Theme>['MuiAppBar'] = { styleOverrides: appBarCss };

export default MuiAppBar;
