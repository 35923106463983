import { partnerInfoT } from './partner.types';
import partnerPortalApi from '@shared/api/partnerPortalApi';

export const partnerApi = partnerPortalApi.injectEndpoints({
    endpoints: (builder) => ({
        getPartnerInfo: builder.query<partnerInfoT | null, { userId: number }>({
            query: ({ userId }) => ({
                url: `/private/partners/user/${userId}`,
                method: 'GET',
            }),
        }),
    }),
});

export const useGetPartnerInfoQuery = (params: { userId: number | undefined }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return partnerApi.useGetPartnerInfoQuery({ userId: params.userId! }, { skip: !params.userId });
};
