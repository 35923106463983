/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

export type GuardT<T = string> = () => { passed: boolean; redirect: T };

type GuardedRouteProps = {
    element: ReactNode;
    guards?: GuardT[];
};

export default function GuardedRoute({ element, guards }: GuardedRouteProps): JSX.Element {
    let guardCheckingIndex = 0;

    while (guardCheckingIndex < (guards?.length || 0)) {
        const guardChecked = guards![guardCheckingIndex]();

        if (!guardChecked.passed) {
            return <Navigate to={guardChecked.redirect} replace />;
        }
        guardCheckingIndex += 1;
    }

    return <>{element}</>;
}
