import { UserT } from '../../reducers/AuthReducer/AuthReducerTypes';

export const GET_USER_LOADING = 'GET_USER_LOADING';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const UPDATE_USER_ELIGIBILITY = 'UPDATE_USER_ELIGIBILITY';

export type UpdateEligibilityResponseDataT = {
    is_eligible?: boolean;
};

export type GetUserResponseDataT = {
    user: UserT;
    access_token: string;
    expires_in: number;
};

export type GetUserResponseErrorT = {
    message?: string;
    list?: string[];
};

export type GetUserLoadingT = {
    type: typeof GET_USER_LOADING;
};

export type UpdateUserEligibilityT = {
    type: typeof UPDATE_USER_ELIGIBILITY;
    payload: UpdateEligibilityResponseDataT;
};

export type GetUserFailedT = {
    type: typeof GET_USER_FAILED;
    error: GetUserResponseErrorT;
};

export type GetUserSuccessT = {
    type: typeof GET_USER_SUCCESS;
    payload: {
        user: UserT;
    };
};

export type GetUserDispatchTypesT = GetUserLoadingT | GetUserFailedT | GetUserSuccessT | UpdateUserEligibilityT;
