import { Container, Grid, Box, Link, Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import IconCheck from './icon-check.svg';

import { ForgotPasswordPageViewPropsI } from './ForgotPasswordPageTypes';
import useForgotPasswordPageStyle from './ForgotPasswordPage.style';
import { LabelTextField, LoadingButton } from '@shared/deprecated';

const ForgotPasswordPageView = ({ formik, loading, t, msgOpen }: ForgotPasswordPageViewPropsI): JSX.Element => {
    const { classes } = useForgotPasswordPageStyle();

    return (
        <Container className={classes.root}>
            <Box borderRadius={1.6} className={classes.box} px={10} py={10}>
                {!msgOpen && (
                    <>
                        <Box mb={3} textAlign="center">
                            <Typography className={classes.title} component="h1" variant="h3">
                                {t('pages.forgot-password.reset-password')}
                            </Typography>
                        </Box>
                        <form id="forgot-password-form" onSubmit={formik.handleSubmit}>
                            <Grid spacing={2} container>
                                <Grid xs={12} item>
                                    <LabelTextField
                                        autoComplete="email"
                                        disabled={loading}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        id="email"
                                        label={t('pages.login.email-address')}
                                        name="email"
                                        type="text"
                                        value={formik.values.email}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Box my={4}>
                                <LoadingButton
                                    color="primary"
                                    loading={loading}
                                    text={t('pages.forgot-password.send-reset-instructions')}
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                />
                            </Box>
                            <Box textAlign="center">
                                <Link className={classes.loginLink} component={RouterLink} to="../login">
                                    {t('constants.cancel')}
                                </Link>
                            </Box>
                        </form>
                    </>
                )}
                {msgOpen && (
                    <div className={classes.wrapper}>
                        <Button className={classes.circle}>
                            <IconCheck className={classes.iconCheck} />
                        </Button>
                        <Typography className={classes.msg}>
                            <strong>{t('pages.forgot-password.sent-msg')}</strong>
                            <br />
                            <br />
                            {t('pages.forgot-password.sent-redirect')}
                        </Typography>
                    </div>
                )}
            </Box>
        </Container>
    );
};

export default ForgotPasswordPageView;
