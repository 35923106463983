import { FarmSeasonT } from '../../reducers/FarmSeasonReducer/FarmSeasonReducerTypes';

export const FARM_SEASON_UPDATE_LOADING = 'FARM_SEASON_UPDATE_LOADING';
export const FARM_SEASON_UPDATE_SUCCESS = 'FARM_SEASON_UPDATE_SUCCESS';
export const FARM_SEASON_UPDATE_FAILED = 'FARM_SEASON_UPDATE_FAILED';

export type FarmSeasonUpdateFailedDataT = {
    message?: string;
    list?: string[];
};

export type FarmSeasonUpdateLoadingT = {
    type: typeof FARM_SEASON_UPDATE_LOADING;
};
export type FarmSeasonUpdateSuccessT = {
    type: typeof FARM_SEASON_UPDATE_SUCCESS;
    payload: FarmSeasonT;
};

export type FarmSeasonUpdateFailedT = {
    type: typeof FARM_SEASON_UPDATE_FAILED;
    error: FarmSeasonUpdateFailedDataT;
};

export type FarmSeasonUpdateDispatchTypes =
    | FarmSeasonUpdateLoadingT
    | FarmSeasonUpdateSuccessT
    | FarmSeasonUpdateFailedT;
