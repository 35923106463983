import { Components, Theme } from '@mui/material';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { SCTheme } from '@soil-capital/ui-kit/theme';

const linkCss: ComponentsOverrides<Theme>['MuiLink'] = {
    root: {
        ...SCTheme.typography.deprecated_body1,
    },
};

const MuiLink: Components<Theme>['MuiLink'] = { styleOverrides: linkCss };

export default MuiLink;
