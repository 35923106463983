import { Components, ComponentsOverrides, Theme } from '@mui/material';

const tabsCss: ComponentsOverrides<Theme>['MuiTabs'] = {
    root: {
        backgroundColor: 'transparent',
        borderRadius: '15px 15px 0 0',
    },
    indicator: ({ theme }) => ({
        backgroundColor: theme.palette.deprecated_driftwood.dark,
    }),
    flexContainer: ({ theme }) => ({
        boxSizing: 'border-box',
        borderBottom: `2px solid ${theme.palette.deprecated_spanishWhiteShadow}`,
    }),
};

const MuiTabs: Components<Theme>['MuiTabs'] = { styleOverrides: tabsCss };

export default MuiTabs;
