import { FarmSeasonFieldT } from '../../reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { PostFarmSeasonOperationTillageApiT } from '../../../services/farmSeasonOperationApiService/farmSeasonOperationApiServiceTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_OPERATION_TILLAGE_LOADING = 'POST_FARM_SEASON_OPERATION_TILLAGE_LOADING';
export const POST_FARM_SEASON_OPERATION_TILLAGE_FAILED = 'POST_FARM_SEASON_OPERATION_TILLAGE_FAILED';
export const POST_FARM_SEASON_OPERATION_TILLAGE_SUCCESS = 'POST_FARM_SEASON_OPERATION_TILLAGE_SUCCESS';

export type PostFarmSeasonOperationTillageDataT = PostFarmSeasonOperationTillageApiT;

export type PostFarmSeasonOperationTillageResponseDataT = {
    fields: FarmSeasonFieldT[];
};

export type FarmSeasonOperationTillagePostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonOperationTillageDispatchTypesT>,
) => Promise<PostFarmSeasonOperationTillageResponseErrorT>;

export type PostFarmSeasonOperationTillageResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonOperationTillageLoadingT = {
    type: typeof POST_FARM_SEASON_OPERATION_TILLAGE_LOADING;
};

export type PostFarmSeasonOperationTillageFailedT = {
    type: typeof POST_FARM_SEASON_OPERATION_TILLAGE_FAILED;
    error: PostFarmSeasonOperationTillageResponseErrorT;
};

export type PostFarmSeasonOperationTillageSuccessT = {
    type: typeof POST_FARM_SEASON_OPERATION_TILLAGE_SUCCESS;
    payload: PostFarmSeasonOperationTillageResponseDataT;
};

export type PostFarmSeasonOperationTillageDispatchTypesT =
    | PostFarmSeasonOperationTillageLoadingT
    | PostFarmSeasonOperationTillageFailedT
    | PostFarmSeasonOperationTillageSuccessT;
