import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import { ReactNode } from 'react';
import RootReducer from './store/reducers/RootReducer';
import { AppStateT } from './store/store';
import useAppDispatch from './hooks/useAppDispatch';

export * from './components';

export const LegacyThemeProvider = ({ children }: { children: ReactNode }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export const legacyReducers = RootReducer;

export { type AppStateT, useAppDispatch };
