import { MachineryT } from '../../reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const MACHINERIES_GET_LOADING = 'MACHINERIES_GET_LOADING';
export const MACHINERIES_GET_SUCCESS = 'MACHINERIES_GET_SUCCESS';
export const MACHINERIES_GET_FAILED = 'MACHINERIES_GET_FAILED';

export type MachineriesGetSuccessDataT = MachineryT[];
export type MachineriesGetFailedDataT = {
    message?: string;
    list?: string[];
};

export type MachineriesGetCallBackT = (
    dispatch: Dispatch<MachineriesDispatchTypes>,
) => Promise<MachineriesGetFailedDataT>;
export type MachineriesGetLoadingT = {
    type: typeof MACHINERIES_GET_LOADING;
};

export type MachineriesGetSuccessT = {
    type: typeof MACHINERIES_GET_SUCCESS;
    payload: MachineriesGetSuccessDataT;
};

export type MachineriesGetFailedT = {
    type: typeof MACHINERIES_GET_FAILED;
    error: MachineriesGetFailedDataT;
};

export type MachineriesDispatchTypes = MachineriesGetLoadingT | MachineriesGetSuccessT | MachineriesGetFailedT;
