import { FarmSeasonFieldCropDataT } from '../../reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_DRAINAGE_LOADING = 'POST_FARM_SEASON_FIELDS_DRAINAGE_LOADING';
export const POST_FARM_SEASON_FIELDS_DRAINAGE_FAILED = 'POST_FARM_SEASON_FIELDS_DRAINAGE_FAILED';
export const POST_FARM_SEASON_FIELDS_DRAINAGE_SUCCESS = 'POST_FARM_SEASON_FIELDS_DRAINAGE_SUCCESS';

export type FarmSeasonFieldsDrainageT = {
    id?: number;
    name: string;
    area: number;
    field_crops?: FarmSeasonFieldCropDataT[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    polygon: any;
    has_drainage?: boolean | null;
};

export type PostFarmSeasonFieldsDrainageDataT = {
    drainage: boolean;
    fields: FarmSeasonFieldsDrainageT[];
};

export type PostFarmSeasonFieldsDrainageResponseDataT = {
    fields: FarmSeasonFieldsDrainageT[];
    installation: {
        onboarding: {
            finished: string[];
            drainage: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsDrainagePostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsDrainageDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsDrainageResponseErrorT>;

export type PostFarmSeasonFieldsDrainageResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsDrainageLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_DRAINAGE_LOADING;
};

export type PostFarmSeasonFieldsDrainageFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_DRAINAGE_FAILED;
    error: PostFarmSeasonFieldsDrainageResponseErrorT;
};

export type PostFarmSeasonFieldsDrainageSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_DRAINAGE_SUCCESS;
    payload: PostFarmSeasonFieldsDrainageResponseDataT;
};

export type PostFarmSeasonFieldsDrainageDispatchTypesT =
    | PostFarmSeasonFieldsDrainageLoadingT
    | PostFarmSeasonFieldsDrainageFailedT
    | PostFarmSeasonFieldsDrainageSuccessT;
