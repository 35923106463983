import { makeStyles } from '@soil-capital/ui-kit/style';

const useFullPageLayoutStyle = makeStyles()((theme) => ({
    FullPageLayout: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.default,
        padding: `${theme.spacing(10)} ${theme.spacing(9)}`,
        position: 'relative',
        overflow: 'hidden auto',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: '#e2d0b6',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b17c43',
            borderRadius: '15px',
        },
    },
    pageHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
}));

export default useFullPageLayoutStyle;
