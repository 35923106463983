import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../reducers/AuthReducer/AuthReducer';

import farmReducer from './FarmReducer/FarmReducer';
import staticReducer from './StaticReducer/StaticReducer';
import routingReducer from './RoutingReducer/RoutingReducer';
import FarmSeasonLivestockReducer from './FarmSeasonLivestockReducer/FarmSeasonLivestockReducer';
import farmSeasonReducer from './FarmSeasonReducer/FarmSeasonReducer';
import FarmSeasonFieldReducer from './FarmSeasonFieldReducer/FarmSeasonFieldReducer';
import FarmSeasonFieldsPreviousHarvestReducer from './FarmSeasonFieldPreviousHarvestReducer/FarmSeasonFieldPreviousHarvestReducer';
import FarmSeasonCropReducer from './FarmSeasonCropReducer/FarmSeasonCropReducer';
import ErrorsReducer from './ErrorsReducer/ErrorsReducer';
import FertiliserReducer from './FertiliserReducer/FertiliserReducer';
import notificationReducer from './NotificationReducer/NotificationReducer';
import AppReducer from './AppReducer/AppReducer';
import subscriptionReducer from './SubscriptionReducer/SubscriptionReducer';
import SuggestionSlice from './Onboarding/Suggestion.slice';
import BillingDetailsReducer from './BillingDetails/BillingDetails';

const RootReducer = {
    app: AppReducer,
    authLegacy: authReducer,
    subscription: subscriptionReducer,
    farm: farmReducer,
    farmSeasonLivestock: FarmSeasonLivestockReducer,
    farmSeason: farmSeasonReducer,
    static: staticReducer,
    routing: routingReducer,
    farmSeasonField: FarmSeasonFieldReducer,
    farmSeasonFieldPreviousHarvest: FarmSeasonFieldsPreviousHarvestReducer,
    farmSeasonCrop: FarmSeasonCropReducer,
    fertiliser: FertiliserReducer,
    errors: ErrorsReducer,
    notifications: notificationReducer,
    onboarding: combineReducers({
        suggestions: SuggestionSlice,
    }),
    billingDetails: BillingDetailsReducer,
};

export default RootReducer;
