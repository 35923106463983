import { SEASON_GET_LOADING, SEASON_GET_SUCCESS } from '../../actions/StaticActions/seasonGetTypes';
import { LIVESTOCKS_GET_LOADING, LIVESTOCKS_GET_SUCCESS } from '../../actions/StaticActions/LivestocksActionsTypes';
import {
    COUNTRY_GET_LOADING,
    COUNTRY_GET_SUCCESS,
    REGION_GET_LOADING,
    REGION_GET_SUCCESS,
} from '../../actions/StaticActions/StaticActionsTypes';
import {
    TRANSLATION_GET_FAILED,
    TRANSLATION_GET_LOADING,
    TRANSLATION_GET_SUCCESS,
} from '../../actions/StaticActions/TranslationsGetActionsTypes';
import {
    LANGUAGES_GET_FAILED,
    LANGUAGES_GET_LOADING,
    LANGUAGES_GET_SUCCESS,
} from '../../actions/StaticActions/LanguagesGetActionsTypes';
import { USER_CHANGE_LANGUAGE_LOADING } from '../../actions/AuthActions/UserChangeLanguageTypes';
import {
    SOIL_LIST_GET_FAILED,
    SOIL_LIST_GET_LOADING,
    SOIL_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/SoilGetListActionTypes';
import {
    PH_LIST_GET_FAILED,
    PH_LIST_GET_LOADING,
    PH_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/PhGetListActionTypes';
import {
    DRAINAGE_LIST_GET_FAILED,
    DRAINAGE_LIST_GET_LOADING,
    DRAINAGE_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/DrainageGetListActionTypes';
import {
    ORGANIC_MATTER_LIST_GET_FAILED,
    ORGANIC_MATTER_LIST_GET_LOADING,
    ORGANIC_MATTER_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/OrganicMatterGetListActionTypes';
import {
    MOISTURE_LIST_GET_FAILED,
    MOISTURE_LIST_GET_LOADING,
    MOISTURE_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/MoistureGetListActionTypes';
import {
    TILLAGE_LIST_GET_FAILED,
    TILLAGE_LIST_GET_LOADING,
    TILLAGE_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/TillageGetListActionTypes';
import {
    CROPS_GET_FAILED,
    CROPS_GET_LOADING,
    CROPS_GET_SUCCESS,
} from '../../actions/StaticActions/CropsGetActionsTypes';
import {
    CURRENCIES_GET_FAILED,
    CURRENCIES_GET_LOADING,
    CURRENCIES_GET_SUCCESS,
} from '../../actions/StaticActions/currenciesGetTypes';
import {
    IRRIGATION_METHOD_LIST_GET_FAILED,
    IRRIGATION_METHOD_LIST_GET_LOADING,
    IRRIGATION_METHOD_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/IrrigationMethodGetListActionTypes';
import {
    IRRIGATION_WATER_SOURCE_LIST_GET_FAILED,
    IRRIGATION_WATER_SOURCE_LIST_GET_LOADING,
    IRRIGATION_WATER_SOURCE_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/IrrigationWaterSourceGetListActionTypes';
import {
    IRRIGATION_POWER_SOURCE_LIST_GET_FAILED,
    IRRIGATION_POWER_SOURCE_LIST_GET_LOADING,
    IRRIGATION_POWER_SOURCE_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/IrrigationPowerSourceGetListActionTypes';
import { TREES_GET_FAILED, TREES_GET_LOADING, TREES_GET_SUCCESS } from '../../actions/StaticActions/TreesGetTypes';
import {
    TILLAGE_PRACTICE_LIST_GET_FAILED,
    TILLAGE_PRACTICE_LIST_GET_LOADING,
    TILLAGE_PRACTICE_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/TillagePracticeGetListActionTypes';
import {
    PESTICIDES_LIST_GET_FAILED,
    PESTICIDES_LIST_GET_LOADING,
    PESTICIDES_LIST_GET_SUCCESS,
} from '../../actions/StaticActions/PesticidesGetListActionTypes';
import {
    MACHINERIES_GET_FAILED,
    MACHINERIES_GET_LOADING,
    MACHINERIES_GET_SUCCESS,
} from '../../actions/StaticActions/MachineryListGetTypes';

import { StaticReducerStateT } from './StaticReducerType';
import { Reducer } from '@reduxjs/toolkit';
import { AppActionsT } from '../../actions/AppActions/AppActionsTypes';

export const defaultStaticState: StaticReducerStateT = {
    countriesLoading: false,
    countries: [],
    currenciesLoading: false,
    currencies: [],
    frequencyListLoading: false,
    frequencyList: [],
    treesLoading: false,
    trees: [],
    regions: [],
    regionsLoading: false,
    livestocksLoading: false,
    livestocks: [],
    seasonsLoading: false,
    seasons: null,
    translationsLoading: false,
    translations: null,
    languagesLoading: false,
    languages: [],
    soilListLoading: false,
    soilList: [],
    pHListLoading: false,
    pHList: [],
    moistureListLoading: false,
    moistureList: [],
    drainageListLoading: false,
    drainageList: [],
    organicMatterLevelListLoading: false,
    organicMatterLevelList: [],
    tillageListLoading: false,
    tillageList: [],
    tillagePracticeListLoading: false,
    tillagePracticeList: [],
    cropsLoading: false,
    crops: [],
    irrigationMethodListLoading: false,
    irrigationMethodList: [],
    irrigationWaterSourceListLoading: false,
    irrigationWaterSourceList: [],
    irrigationPowerSourceListLoading: false,
    irrigationPowerSourceList: [],
    pesticidesListLoading: false,
    pesticidesList: [],
    machineryListLoading: false,
    machineryList: [],
};

// TODO: MSC-817 add dispatch types for irrigation
const staticReducer: Reducer<StaticReducerStateT, AppActionsT> = (state, action): StaticReducerStateT => {
    if (!state) {
        return defaultStaticState;
    }

    switch (action.type) {
        case COUNTRY_GET_LOADING:
            return { ...state, countriesLoading: true };
        case COUNTRY_GET_SUCCESS:
            return { ...state, countriesLoading: false, countries: action.payload };
        case REGION_GET_LOADING:
            return { ...state, regionsLoading: true };
        case REGION_GET_SUCCESS:
            return { ...state, regionsLoading: false, regions: action.payload };
        case LIVESTOCKS_GET_LOADING:
            return { ...state, livestocksLoading: true };
        case LIVESTOCKS_GET_SUCCESS:
            return { ...state, livestocksLoading: false, livestocks: action.payload };
        case SEASON_GET_LOADING:
            return { ...state, seasonsLoading: true };
        case SEASON_GET_SUCCESS:
            return { ...state, seasonsLoading: false, seasons: action.payload };
        case TRANSLATION_GET_LOADING:
        case USER_CHANGE_LANGUAGE_LOADING:
            return { ...state, translationsLoading: true };
        case TRANSLATION_GET_SUCCESS:
            return {
                ...state,
                translationsLoading: false,
                translations: {
                    ...state.translations,
                    [action.payload.iso_code]: { translation: action.payload.translation },
                },
            };
        case TRANSLATION_GET_FAILED:
            return { ...state, translationsLoading: false };
        case LANGUAGES_GET_LOADING:
            return { ...state, languagesLoading: true };
        case LANGUAGES_GET_SUCCESS:
            return { ...state, languagesLoading: false, languages: action.payload };
        case LANGUAGES_GET_FAILED:
            return { ...state, languagesLoading: false };
        case SOIL_LIST_GET_LOADING:
            return { ...state, soilListLoading: true };
        case SOIL_LIST_GET_SUCCESS:
            return { ...state, soilList: action.payload, soilListLoading: false };
        case SOIL_LIST_GET_FAILED:
            return { ...state, soilListLoading: false };
        case PH_LIST_GET_LOADING:
            return { ...state, pHListLoading: true };
        case PH_LIST_GET_SUCCESS:
            return { ...state, pHList: action.payload, pHListLoading: false };
        case PH_LIST_GET_FAILED:
            return { ...state, pHListLoading: false };
        case DRAINAGE_LIST_GET_LOADING:
            return { ...state, drainageListLoading: true };
        case DRAINAGE_LIST_GET_SUCCESS:
            return { ...state, drainageList: action.payload, drainageListLoading: false };
        case DRAINAGE_LIST_GET_FAILED:
            return { ...state, drainageListLoading: false };
        case ORGANIC_MATTER_LIST_GET_LOADING:
            return { ...state, organicMatterLevelListLoading: true };
        case ORGANIC_MATTER_LIST_GET_SUCCESS:
            return { ...state, organicMatterLevelList: action.payload, organicMatterLevelListLoading: false };
        case ORGANIC_MATTER_LIST_GET_FAILED:
            return { ...state, organicMatterLevelListLoading: false };
        case MOISTURE_LIST_GET_LOADING:
            return { ...state, moistureListLoading: true };
        case MOISTURE_LIST_GET_SUCCESS:
            return { ...state, moistureList: action.payload, moistureListLoading: false };
        case MOISTURE_LIST_GET_FAILED:
            return { ...state, moistureListLoading: false };
        case TILLAGE_LIST_GET_LOADING:
            return { ...state, tillageListLoading: true };
        case TILLAGE_LIST_GET_SUCCESS:
            return { ...state, tillageList: action.payload, tillageListLoading: false };
        case TILLAGE_LIST_GET_FAILED:
            return { ...state, tillageListLoading: false };
        case TILLAGE_PRACTICE_LIST_GET_LOADING:
            return { ...state, tillagePracticeListLoading: true };
        case TILLAGE_PRACTICE_LIST_GET_SUCCESS:
            return { ...state, tillagePracticeList: action.payload, tillagePracticeListLoading: false };
        case TILLAGE_PRACTICE_LIST_GET_FAILED:
            return { ...state, tillagePracticeListLoading: false };
        case CROPS_GET_LOADING:
            return { ...state, cropsLoading: true };
        case CROPS_GET_SUCCESS:
            return { ...state, cropsLoading: false, crops: action.payload };
        case CROPS_GET_FAILED:
            return { ...state, cropsLoading: false };
        case CURRENCIES_GET_LOADING:
            return { ...state, currenciesLoading: true };
        case CURRENCIES_GET_SUCCESS:
            return { ...state, currenciesLoading: false, currencies: action.payload };
        case CURRENCIES_GET_FAILED:
            return { ...state, currenciesLoading: false };
        case TREES_GET_LOADING:
            return { ...state, treesLoading: true };
        case TREES_GET_SUCCESS:
            return { ...state, treesLoading: false, trees: action.payload };
        case TREES_GET_FAILED:
            return { ...state, treesLoading: false };
        case IRRIGATION_METHOD_LIST_GET_LOADING:
            return { ...state, irrigationMethodListLoading: true };
        case IRRIGATION_METHOD_LIST_GET_SUCCESS:
            return { ...state, irrigationMethodList: action.payload, irrigationMethodListLoading: false };
        case IRRIGATION_METHOD_LIST_GET_FAILED:
            return { ...state, irrigationMethodListLoading: false };
        case IRRIGATION_WATER_SOURCE_LIST_GET_LOADING:
            return { ...state, irrigationWaterSourceListLoading: true };
        case IRRIGATION_WATER_SOURCE_LIST_GET_SUCCESS:
            return { ...state, irrigationWaterSourceList: action.payload, irrigationWaterSourceListLoading: false };
        case IRRIGATION_WATER_SOURCE_LIST_GET_FAILED:
            return { ...state, irrigationWaterSourceListLoading: false };
        case IRRIGATION_POWER_SOURCE_LIST_GET_LOADING:
            return { ...state, irrigationPowerSourceListLoading: true };
        case IRRIGATION_POWER_SOURCE_LIST_GET_SUCCESS:
            return { ...state, irrigationPowerSourceList: action.payload, irrigationPowerSourceListLoading: false };
        case IRRIGATION_POWER_SOURCE_LIST_GET_FAILED:
            return { ...state, irrigationPowerSourceListLoading: false };
        case PESTICIDES_LIST_GET_LOADING:
            return { ...state, pesticidesListLoading: true };
        case PESTICIDES_LIST_GET_SUCCESS:
            return { ...state, pesticidesList: action.payload, pesticidesListLoading: false };
        case PESTICIDES_LIST_GET_FAILED:
            return { ...state, pesticidesListLoading: false };
        case MACHINERIES_GET_LOADING:
            return { ...state, machineryListLoading: true };
        case MACHINERIES_GET_SUCCESS:
            return { ...state, machineryList: action.payload, machineryListLoading: false };
        case MACHINERIES_GET_FAILED:
            return { ...state, machineryListLoading: false };
        default:
            return state;
    }
};

export default staticReducer;
