import { Dispatch } from '@reduxjs/toolkit';
import { FarmSeasonFieldT } from '../../reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';

export const POST_FARM_SEASON_FIELDS_NAME_LOADING = 'POST_FARM_SEASON_FIELDS_NAME_LOADING';
export const POST_FARM_SEASON_FIELDS_NAME_FAILED = 'POST_FARM_SEASON_FIELDS_NAME_FAILED';
export const POST_FARM_SEASON_FIELDS_NAME_SUCCESS = 'POST_FARM_SEASON_FIELDS_NAME_SUCCESS';

export type FarmSeasonFieldNamePostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldNameDispatchTypesT>,
) => Promise<PostFarmSeasonFieldNameResponseErrorT>;

export type PostFarmSeasonFieldRenameDataT = {
    name: string;
};

export type PostFarmSeasonFieldNameResponseDataT = {
    fields: FarmSeasonFieldT[];
};

export type PostFarmSeasonFieldNameResponseErrorT = {
    message?: string;
    error?: string;
};

export type PostFarmSeasonFieldNameLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_NAME_LOADING;
};

export type PostFarmSeasonFieldNameFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_NAME_FAILED;
    error: PostFarmSeasonFieldNameResponseErrorT;
};

export type PostFarmSeasonFieldNameSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_NAME_SUCCESS;
    payload: PostFarmSeasonFieldNameResponseDataT;
};

export type PostFarmSeasonFieldNameDispatchTypesT =
    | PostFarmSeasonFieldNameLoadingT
    | PostFarmSeasonFieldNameFailedT
    | PostFarmSeasonFieldNameSuccessT;
